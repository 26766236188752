export let fallbackLanguage: string = "en";

export const setFallbackLanguage = (language: string) => {
  fallbackLanguage = language;
}

export const getLanguage = () => {
  const culture: string = window.navigator.language;
  if (culture) {
    return culture.split("-")[0];
  } else {
    return fallbackLanguage;
  }
}

export const getCountry = () => {
  const culture: string = window.navigator.language;
  if (culture) {
    return culture.includes("-") ? culture.split("-")[1] : "";
  } else {
    return "";
  }
}