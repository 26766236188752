import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { CompanyBullet } from '../icons/CompanyBullet';
import { WeAutomateAutomationIcon } from '../icons/WeAutomateAutomationIcon';
import { useMediaQuery } from 'react-responsive';

import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { AppActionType, AppContext } from '../providers/AppProvider';
import { TranslatedContent } from './TranslatedContent';

export const WeAutomateAutomation = () => {
    const { t, i18n } = useTranslation();
    const content = "weautomateautomation";
    let { language } = useParams();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const colorContext = useContext(ColorScheduleContext);
    const colorSchedule = colorContext.colorSchedule;
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    // Check a language change from the browser's url
    if (language) {
        language = language.toLowerCase();
        // Return the languages supported:
        const langs = Object.keys(i18next.services.resourceStore.data);
        // Check whether it is supported
        if (langs.includes(language))
            if (language !== i18n.language)
                // This will redraw the Navigator
                i18n.changeLanguage(language);
    } else {
        language = i18n.language;
    }

    useEffect(() => {
        if (content !== context.app.content) {
            context.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            if (window.history.state.key == null) {
                console.log(`Goto /${content}/${language}`);
                navigate(`/${content}/${language}`, { replace: false });
            }
        }        
    })

    const controlStyle = {
        backgroundColor: colorSchedule.headerColorFrom
    }

    const iconColor = colorSchedule.iconColor;
    const stopColor = colorSchedule.icon2Color;
    const containerColor = colorSchedule.icon2Color;
    const containerStyle = { 
        color: colorSchedule.containerTextColor,
        background: `linear-gradient(to bottom,  ${colorSchedule.containerColorFrom} 0%,${colorSchedule.containerColorTo} 100%)`,
        width: "15em", height: '4em',
        padding: "15px",
        // fontWeight: "bold", 
        fontSize: "1.25em",
        marginRight: '25px',
//        cursor: "pointer",
        boxShadow: `10px 5px 5px ${colorSchedule.containerShadowColor}`
    }
    const textStyle = { 
        color: colorSchedule.containerExplanationColor
    }
    const display = isMobile ? "block" : "table-cell";

    return (
        <>
            <TranslatedContent content="weautomateautomation" />
            <Styles isMobile={isMobile}>
                <>
                        <EurekaRole 
                            prefix = "WeAutomateAutomation.SoftwareAnalyst"
                            content = "softwareanalyst"
                            color={iconColor} stopColor={stopColor}
                            containerStyle = {containerStyle}
                            textStyle = {textStyle}
                            t = {t}
                        />

                        <EurekaRole 
                            prefix = "WeAutomateAutomation.SolutionArchitect"
                            content = "solutionarchitect"
                            color={iconColor} stopColor={stopColor}
                            containerStyle = {containerStyle}
                            textStyle = {textStyle}
                            t = {t}
                        />

                        <EurekaRole 
                            prefix = "WeAutomateAutomation.ForwardEngineer"
                            content = "forwardengineer"                            
                            color={iconColor} stopColor={stopColor}
                            containerStyle = {containerStyle}
                            textStyle = {textStyle}
                            t = {t}
                        />

                        <EurekaRole 
                            prefix = "WeAutomateAutomation.ReverseEngineer"
                            content = "reverseengineer"                            
                            color={iconColor} stopColor={stopColor}
                            containerStyle = {containerStyle}
                            textStyle = {textStyle}
                            t = {t}
                        />

                    {/* <div className='paragraph'></div>
                    <div>
                        <WeAutomateAutomationIcon color={colorSchedule.containerColorFrom} height='300px' width= "450px"/>
                    </div> */}
                </>            
            </Styles>
        </>
      );}

export const EurekaRole = (props: any) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
        
    let i = -1;
    const link = props.content && props.content != "";

    const changeContent = (value: string) => {
        navigate(`/${value}`, { replace: false });
    };
    const dummy = () => {
    };

    return (
        <div className='main-table' key={props.prefix}>
            <div className='main-row' key={props.prefix + "_1"}>
                <div className='main-cell' key={props.prefix + "_2"}>
                    <div style={props.containerStyle} key={props.prefix + "_3"} 
                            onClick={() => link ? (changeContent(props.content)) : dummy()}
                            >
                        <a  key={"innerLink_" + props.prefix}
                            onMouseEnter={()=>{
                                if (link)
                                    setHover(true);
                            }}
                            onMouseLeave={()=>{
                                if (link)
                                    setHover(false);
                            }}
                            style={{
                                color: "white",
                                cursor: link ? "pointer" : "text",                              
                                textDecoration: hover && link ? "underline" : "none"
                            }}
                            // onClick={() => changeContent(props.content)}
                            >
                            {props.t(props.prefix + ".Name")}
                        </a>
                    </div>
                </div>
                <div className='main-cell' key={props.prefix + "_4"}>
                    <div className='table' style={props.textStyle} key={props.prefix + "_5"}>
                        {(props.t(props.prefix + ".Items", { returnObjects: true }) as any).map((item: string) => {
                            i++;
                            return (
                                <div className='row' key={props.prefix + "6_" + i}>
                                    <div className='bullet-cell' key={props.prefix + "7_" + i}><CompanyBullet color={props.iconColor} stopColor={props.icon2Color}/></div>
                                    <div className='cell' key={props.prefix + "8_" + i}>
                                        {item}
                                    </div>
                                </div>
                            );
                        })}                                    
                    </div>
                </div>
            </div>
        </div>
        );
    }


const Styles = styled.div<{ isMobile: boolean }>`
a:hover {
    text-decoration: none;
    cursor: pointer;
}
.title {
    font-weight: bold;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
}
.paragraph {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}
.main-table {
    display: table;
    padding: 0px;
    margin: 0px;
    margin-left: ${(props) => (props.isMobile ? "15px" : "15px")};;
    margin-top: ${(props) => (props.isMobile ? "10px" : "15px")};
}
.main-row {
    text-align: center;
    display: table-row;
    padding: 0px;
    margin: 0px;
    width: 90%;
}
.main-cell {
    padding: 0px;
    margin: 0px;
    padding-left: 0px;
    margin-left: 0px;    
    vertical-align: top;
    text-align: left;
    margin-bottom: ${(props) => (props.isMobile ? "15px" : "0px")};       
    display: ${(props) => (props.isMobile ? "block" : "table-cell")};     
}
.table {
    display: table;
    width: min-content;
    padding: 0px;
    margin: 0px;
    margin-top: -5px;
    width: 100%;
}
.row {
    text-align: center;
    display: table-row;
    padding: 0px;
    margin: 0px;
    width: 75%;    
}
.cell {
    padding: 5px;
    padding-top: 1px;
    padding-left: 8px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
}
.bullet-cell {
    padding: 5px;
    padding-top: 0px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
}
`;
