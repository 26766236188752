import React, { useContext } from 'react';
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import Navigator from "./Navigator";
import { Header } from './Header'
import { Layout } from "./Layout";
import { Content } from "./Content";
import { AppProvider } from "../providers/AppProvider";
import { Contact } from "./Contact";
import { Colors } from "./Colors";
import { ContactProvider } from "../providers/ContactProvider";
import { Workspace } from './Workspace';
import { WeAutomateAutomation } from './WeAutomateAutomation';
import { SoftwareAnalyst } from './SoftwareAnalyst';
import { SolutionArchitect } from './SolutionArchitect';
import { ForwardEngineer } from './ForwardEngineer';
import { ReverseEngineer } from './ReverseEngineer';
import { ColorScheduleContext, DayLightColorSchedule, IColorSchedule, NightColorSchedule, colorScheduleStorage, getColors, setColors } from '../providers/ColorScheduleProvider';
import Image from './../assets/code.jpg'
import { Intro } from './Intro';
import { TranslatedContent } from './TranslatedContent';
import { getLanguage, fallbackLanguage } from '../utils/cultures';
import i18next from 'i18next';
import { resources } from '../translations';
import { set } from 'core-js/core/dict';

export let historyPushed = false;

const App = (props: any) => {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;

  const nightMode = localStorage.getItem("nightMode");
  // const colorString = localStorage.getItem("colorSchedule");
  // if (colorString) {
  //   const colors: string[] = JSON.parse(colorString);
  //   setColors(colorSchedule, colors);
  // }
  const colors = getColors(nightMode == "true" ? NightColorSchedule : DayLightColorSchedule); 
  setColors(colorSchedule, colors);
  
  let languages: string[] = Object.keys(i18next.services.resourceStore.data);
  // Check whether i18next was ready else do it ourselves 
  if (!languages || languages.length == 0)
    languages = Object.keys(resources);

  let language = localStorage.getItem("language");
  if (!language) {
    const browserLang = getLanguage();
    if (languages.includes(browserLang))
      language = browserLang;
    else
      language = fallbackLanguage;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeLanguage = (lang: string) => {
    console.log("Changing language?");
    if (i18n?.changeLanguage) {
      console.log("Yes");
      i18n.changeLanguage(lang);
    }
    else
      console.log("i18n hasn't been initialized");
  };

  useEffect(() => {
    if (!initialized) {
      console.log("Not initialized");
      let language = localStorage.getItem("language");
      console.log(`Language from storage: ${language}`);
      if (!language) {
        const lang = getLanguage();
        if (languages.includes(lang))
          language = lang;
        else
          language = fallbackLanguage;
      }
      if (language !== i18n.language) {
        changeLanguage(language || "en");
      }
      setInitialized(true);
    }
  }, [changeLanguage, initialized, colorSchedule]);

  window.addEventListener('popstate', function(event) {
    console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
  }, true);  

  const showBar = true;
  const display = showBar ? "" : "none";

return (
    <Styles>
        <div id="win" style={{ 
            // backgroundImage: `url(${Image})` 
          }} 
          className="win">
          <div id="header" style={{ color: colorSchedule.headerTextColor, 
                                    background: `linear-gradient(to bottom,  ${colorSchedule.headerColorFrom} 0%,${colorSchedule.headerColorTo} 100%)`
            }} className="header">
            <Layout>
              <Header/>
            </Layout>
          </div>
          <BrowserRouter>
            <AppProvider>
              <ContactProvider>
                <div id="bar" style={{  color: colorSchedule.menuTextColor, display: display,
                                        background: `linear-gradient(to bottom,  ${colorSchedule.menuBarColorFrom} 0%,${colorSchedule.menuBarColorTo} 100%)`,
                                        borderTopColor: colorSchedule.menuBarTopColor, borderBottomColor: colorSchedule.menuBarBottomColor}} className="bar">
                    <Layout>
                      <Navigator />
                    </Layout>
                </div>        
                <div id="workspace" 
                     style={{ background: `linear-gradient(to bottom,  ${colorSchedule.jumbotronColorFrom} 0%,${colorSchedule.jumbotronColorTo} 100%)`, }}
                   //   style={{ backgroundImage: `url(${Image})` }}
                      className="workspace">
                  <Layout>
                      <Workspace>
                        <div className="html">
                          <div id="desktop" className="outer-box">
                            <Routes>
                              <Route path="/weautomateautomation/:language" element={<WeAutomateAutomation/>} />
                              <Route path="/weautomateautomation" element={<WeAutomateAutomation/>} /> 
                              <Route path="/softwareanalyst/:language" element={<SoftwareAnalyst/>} />
                              <Route path="/softwareanalyst" element={<SoftwareAnalyst/>} /> 
                              <Route path="/solutionarchitect/:language" element={<SolutionArchitect/>} />
                              <Route path="/solutionarchitect" element={<SolutionArchitect/>} /> 
                              <Route path="/forwardengineer/:language" element={<ForwardEngineer/>} />
                              <Route path="/forwardengineer" element={<ForwardEngineer/>} /> 
                              <Route path="/reverseengineer/:language" element={<ReverseEngineer/>} />
                              <Route path="/reverseengineer" element={<ReverseEngineer/>} /> 
                              <Route path="/welcome/:language" element={<TranslatedContent content= "welcome" language={language}/>} />
                              <Route path="/welcome" element={<TranslatedContent content="welcome" /> } />
                              <Route path="/vision/:language" element={<TranslatedContent content= "vision" language={language}/>} />
                              <Route path="/vision" element={<TranslatedContent content="vision" /> } />
                              <Route path="/contact/:language" element={<Contact/>} />
                              <Route path="/contact" element={<Contact/>} />                          
                              <Route path="/about/:language" element={<TranslatedContent content= "about" language={language}/>} />
                              <Route path="/about" element={<TranslatedContent content="about" /> } />
                              <Route path="/colors/:language"  element={<Colors/>} />
                              <Route path="/colors" element={<Colors/>} />
                              {/* Content the old way, still useful for adhoc extensions:  */}
                              <Route path="/:content/:language" element={<Content/>} />
                              <Route path="/:content" element={<Content/>} />
                              <Route path="/" element={<TranslatedContent content="welcome" /> } />
                            </Routes>
                          </div>
                        </div>
                      </Workspace>
                    </Layout>
                </div>
              </ContactProvider>
            </AppProvider>
          </BrowserRouter>
        </div>
    </Styles>
  );
 }

export default App;

const Styles = styled.div`
  .body {
    height: 100vh;  
    background-color: orange;
  }
  
  .body-small {
    height: 100vh;  
  }

  .win {
    height: 100vh;
    vertical-align: top;  
  }

  .win-small {
    height: 100vh;  
    vertical-align: top;  
  }

  .workspace {
    height: fit-content;  
    vertical-align: top;
  }  

  .workspace-small {
    height: fit-content;
    vertical-align: top;
  }  

  .header {
    vertical-align: top; 
    padding: 0px;
    margin: 0px;
  }

  .bar {
    padding: 0px;
    padding-bottom: 5px;
    border: 0px;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
  }

  .html {
    padding: 0px;
    padding-top: 5px;
    margin: 0px;

  }
  
  .outer-box {
    min-height: 500px; //border: solid 1px; 
    margin: 0px; margin-right: 0px; margin-bottom: 0px;  
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
//    padding-bottom: 15px;
    opacity: 0.95;
    background-color: transparent;
  }

  .box {
    padding: 0px;
    margin: 0px;
    border: solid 0px;
    width: 100%;
      background-color: transparent;
  }
` ;
