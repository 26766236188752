import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';

import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { AppActionType, AppContext } from '../providers/AppProvider';

import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome, color, rgbaToHexa } from '@uiw/react-color';
import { Alpha, Hue, ShadeSlider, Saturation, hsvaToHslaString } from '@uiw/react-color';
import { EditableInput, EditableInputRGBA, EditableInputHSLA } from '@uiw/react-color';

const initialErrors: Record<string, any> = {};
const initialTouched: Record<string, boolean> = {};

export const Colors = () => {
    const { t, i18n } = useTranslation();
    const content = "colors";
    let { language } = useParams();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const colorContext = useContext(ColorScheduleContext);
    const colorSchedule = colorContext.colorSchedule;

    const componentToHex = (c: any) => {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
      
    const rgbToHex = (r: any, g: any, b: any) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const colorToHex = (color: string) => {
        if (color.startsWith("#")) {            
        } else {
            if (color.toLowerCase().startsWith("rgb(")) {
            } else {               
                let fakeDiv = document.createElement("div");
                fakeDiv.style.color = color;
                document.body.appendChild(fakeDiv);
        
                let style = window.getComputedStyle(fakeDiv);
                color = style.getPropertyValue("color");
                document.body.removeChild(fakeDiv);
            }
            var matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
            var match: any = matchColors.exec(color);
            const r = parseInt(match[1]);
            const g = parseInt(match[2]);
            const b = parseInt(match[3]);    
            color = rgbToHex(r, g, b);
        }
        return color;
    }

    
    const color = colorToHex("#000000");
    const [hex, setHex] = useState(color);

    // Check a language change from the browser's url
    if (language) {
        language = language.toLowerCase();
        // Return the languages supported:
        const langs = Object.keys(i18next.services.resourceStore.data);
        // Check whether it is supported
        if (langs.includes(language))
            if (language !== i18n.language)
                // This will redraw the Navigator
                i18n.changeLanguage(language);
    } else {
        language = i18n.language;
    }


    const onReset = (e: React.FormEvent<HTMLButtonElement>) => {
    };

    useEffect(() => {
        if (content !== context.app.content) {
            context.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            if (window.history.state.key == null) {
                console.log(`Goto /${content}/${language}`);
                navigate(`/${content}/${language}`, { replace: false });
            }
        }        
    })

    const headerFromStyle = {
        backgroundColor: colorSchedule.headerColorFrom
    }
    const headerToStyle = {
        backgroundColor: colorSchedule.headerColorTo
    }
    const headerTextStyle = {
        backgroundColor: colorSchedule.headerTextColor
    }
    const iconStyle = {
        backgroundColor: colorSchedule.iconColor
    }
    const icon2Style = {
        backgroundColor: colorSchedule.icon2Color
    }
    const menuBarFromStyle = {
        backgroundColor: colorSchedule.menuBarColorFrom
    }
    const menuBarToStyle = {
        backgroundColor: colorSchedule.menuBarColorTo
    }
    const menuBarTopStyle = {
        backgroundColor: colorSchedule.menuBarTopColor
    }
    const menuBarBottomStyle = {
        backgroundColor: colorSchedule.menuBarBottomColor
    }
    const menuTextStyle = {
        backgroundColor: colorSchedule.menuTextColor
    }
    const activeMenuStyle = {
        backgroundColor: colorSchedule.activeMenuColor
    }
    const menuToggleStyle = {
        backgroundColor: colorSchedule.menuToggleColor
    }
    const menuToggleLineStyle = {
        backgroundColor: colorSchedule.menuToggleLineColor
    }
    const jumbotronFromStyle = {
        backgroundColor: colorSchedule.jumbotronColorFrom
    }
    const jumbotronToStyle = {
        backgroundColor: colorSchedule.jumbotronColorTo
    }
    const surfaceFromStyle = {
        backgroundColor: colorSchedule.surfaceColorFrom
    }
    const surfaceToStyle = {
        backgroundColor: colorSchedule.surfaceColorTo
    }
    const surfaceTextStyle = {
        backgroundColor: colorSchedule.surfaceTextColor
    }
    const buttonColorFromStyle = {
        backgroundColor: colorSchedule.buttonColorFrom
    }
    const buttonColorToStyle = {
        backgroundColor: colorSchedule.buttonColorTo
    }
    const buttonTextStyle = {
        backgroundColor: colorSchedule.buttonTextColor
    }
    const buttonBorderStyle = {
        backgroundColor: colorSchedule.buttonBorderColor
    }
    const linkStyle = {
        backgroundColor: colorSchedule.linkColor
    }
    const errorStyle = {
        backgroundColor: colorSchedule.errorColor
    }
    const nightModeStyle = {
        backgroundColor: colorSchedule.nightModeColor
    }

    return (
        <Styles>
            <div className="title">{t("Colors.Scheme")}</div>           
            <div className="table">
                <div className="row" >
                    <div className="cell">Header from:</div>
                    <div className="color-cell">
                        <div style={headerFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell">Header to:</div>
                    <div className="color-cell">
                        <div style={headerToStyle} className='color'></div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell">Header text:</div>
                    <div className="color-cell">
                        <div style={headerTextStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Logo:</div>
                    <div className="color-cell">
                        <div style={iconStyle} className='color'></div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell">Logo 2:</div>
                    <div className="color-cell">
                        <div style={icon2Style} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar from:</div>
                    <div className="color-cell">
                        <div style={menuBarFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar top:</div>
                    <div className="color-cell">
                        <div style={menuBarToStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menu text:</div>
                    <div className="color-cell">
                        <div style={menuTextStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar topline:</div>
                    <div className="color-cell">
                        <div style={menuBarTopStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar bottomline:</div>
                    <div className="color-cell">
                        <div style={menuBarFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar toggle:</div>
                    <div className="color-cell">
                        <div style={menuToggleStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Menubar toggleline:</div>
                    <div className="color-cell">
                        <div style={menuToggleLineStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Jumbotron from:</div>
                    <div className="color-cell">
                        <div style={jumbotronFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Jumbotron to:</div>
                    <div className="color-cell">
                        <div style={jumbotronToStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Surface from:</div>
                    <div className="color-cell">
                        <div style={surfaceFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Surface to:</div>
                    <div className="color-cell">
                        <div style={surfaceToStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Surface text:</div>
                    <div className="color-cell">
                        <div style={surfaceTextStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Button from:</div>
                    <div className="color-cell">
                        <div style={buttonColorFromStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Button to:</div>
                    <div className="color-cell">
                        <div style={buttonColorToStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Button text:</div>
                    <div className="color-cell">
                        <div style={buttonTextStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Button border:</div>
                    <div className="color-cell">
                        <div style={buttonBorderStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Link:</div>
                    <div className="color-cell">
                        <div style={linkStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Error:</div>
                    <div className="color-cell">
                        <div style={errorStyle} className='color'></div>
                    </div>
                </div>
                <div className="row" >
                    <div className="cell">Nightmode:</div>
                    <div className="color-cell">
                        <div style={nightModeStyle} className='color'></div>
                    </div>
                </div>
            </div>
            <Sketch
                style={{ marginLeft: 20 }}
                color={hex}
                onChange={(color) => {
                    setHex(color.hex);
                }}
            />
        </Styles>
      );}

const Styles = styled.div`
.box {
    min-height: 500px;  
    margin: 0px; margin-right: 25px; margin-bottom: 25px;  
    padding-bottom: 15px;
}
.title {
    font-weight: bold;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}
.paragraph {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}
.html {
    padding: 0px;
    padding-top: 25px;
    margin-left: 15px;
}
.table {
    display: table;
    width: min-content;
    padding: 15px;
    margin: 15px;
    background-color: lightgray;
    opacity: 100%;
}
.row {
    text-align: center;
    display: table-row;
    padding: 0px;
    padding-left: 25px;
}
.color {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    height: 25px;
    width: 25x;
}
.cell {
    padding: 2px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
    overflow-x: hidden;
    min-width: 200px;
    font-size: 13px;
}
.color-cell {
    padding: 2px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
    overflow-x: hidden;
    min-width: 50px;
    font-size: 13px;
}
`;
