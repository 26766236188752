import * as React from "react";

export const CompagnyIcon = ({
    height = "200px",
    width = "160px",
    color = "black",
    stopColor = "gray",
    strokeWidth = 4,
    ...props
  }: React.SVGProps<SVGElement>) => (

    <svg width={width} height={height}
        viewBox="0 0 280 375"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <line x1="40" y1="35" x2="40" y2="305" stroke={color} strokeWidth={strokeWidth} />
          <line x1="40" y1="110" x2="165" y2="110" stroke={color} strokeWidth={strokeWidth} />
          <line x1="40" y1="270" x2="165" y2="270" stroke={color} strokeWidth={strokeWidth} />
          <line x1="165" y1="108" x2="165" y2="272" stroke={color} strokeWidth={strokeWidth} />
          <circle cx="40" cy="35" r="15" fill={color} />
          <polygon points="40,70 0,110 40,150 80,110" fill={color} />
          <rect x="80" y="150" width="170" height="80" fill={color} />
          <circle cx="40" cy="325" r="25" fill="white" stroke={color} strokeWidth={strokeWidth} />
          <circle cx="40" cy="325" r="15" fill={stopColor} />
      </g>
    </svg>
    );


export function CompagnyIconE5({
      height = "200px",
      width = "160px",
      color = "black",
      stopColor = "gray",
      strokeWidth = 4,
      ...props
    }: React.SVGProps<SVGElement>) {
  return (
      <svg width={width} height={height}
          viewBox="0 0 295 375"
          xmlns="http://www.w3.org/2000/svg">
          <g>
            <line x1="55" y1="35" x2="55" y2="305" stroke={color} strokeWidth={strokeWidth} />
            <line x1="55" y1="110" x2="180" y2="110" stroke={color} strokeWidth={strokeWidth} />
            <line x1="55" y1="270" x2="180" y2="270" stroke={color} strokeWidth={strokeWidth} />
            <line x1="180" y1="108" x2="180" y2="272" stroke={color} strokeWidth={strokeWidth} />
            <circle cx="55" cy="35" r="15" fill={color} />
            <polygon points="55,70 15,110 55,150 95,110" fill={color} />
            <rect x="95" y="150" width="170" height="80" fill={color} />
            <circle cx="55" cy="325" r="25" fill="white" stroke={color} strokeWidth={strokeWidth} />
            <circle cx="55" cy="325" r="15" fill={stopColor} />
        </g>
      </svg>
    );
  }