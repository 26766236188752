import React, { Context, createContext, useReducer, useEffect } from "react";

export const colorScheduleStorage = "ColorScheduleStorage";
const storage = false;

// Properties involved

export interface IColorSchedule {
  headerColorFrom: string;
  headerColorTo: string;
  headerTextColor: string;
  iconColor: string;
  icon2Color: string;
  menuBarColorFrom: string;
  menuBarColorTo: string;
  menuBarTopColor: string;
  menuBarBottomColor: string;
  menuTextColor: string;
  activeMenuColor: string;
  menuToggleColor: string;
  menuToggleLineColor: string;
  jumbotronColorFrom: string;
  jumbotronColorTo: string;
  surfaceColorFrom: string;
  surfaceColorTo: string;
  surfaceTextColor: string;
  buttonColorFrom: string;
  buttonColorTo: string;
  buttonTextColor: string;
  buttonBorderColor: string;
  linkColor: string;
  errorColor: string;
  nightModeColor: string;
  containerColorFrom: string;
  containerColorTo: string;
  containerTextColor: string;
  containerShadowColor: string;
  containerExplanationColor: string;
}

export const getColors = (schedule: IColorSchedule): string[] => {
  const result: string[] = [];
  result.push(schedule.headerColorFrom);
  result.push(schedule.headerColorTo);
  result.push(schedule.headerTextColor);
  result.push(schedule.iconColor);
  result.push(schedule.icon2Color);
  result.push(schedule.menuBarColorFrom);
  result.push(schedule.menuBarColorTo);
  result.push(schedule.menuBarTopColor);
  result.push(schedule.menuBarBottomColor);
  result.push(schedule.menuTextColor);
  result.push(schedule.activeMenuColor);
  result.push(schedule.menuToggleColor);
  result.push(schedule.menuToggleLineColor);
  result.push(schedule.jumbotronColorFrom);
  result.push(schedule.jumbotronColorTo);
  result.push(schedule.surfaceColorFrom);
  result.push(schedule.surfaceColorTo);
  result.push(schedule.surfaceTextColor);
  result.push(schedule.buttonColorFrom);
  result.push(schedule.buttonColorTo);
  result.push(schedule.buttonTextColor);
  result.push(schedule.buttonBorderColor);
  result.push(schedule.linkColor);
  result.push(schedule.errorColor);
  result.push(schedule.nightModeColor);
  result.push(schedule.containerColorFrom);
  result.push(schedule.containerColorTo);
  result.push(schedule.containerTextColor);
  result.push(schedule.containerShadowColor);
  result.push(schedule.containerExplanationColor);  
  return result;
}

export const setColors = (schedule: IColorSchedule, value: string[]): void => {
  schedule.headerColorFrom = value[0];
  schedule.headerColorTo= value[1];
  schedule.headerTextColor = value[2];
  schedule.iconColor = value[3];
  schedule.icon2Color = value[4];
  schedule.menuBarColorFrom = value[5];
  schedule.menuBarColorTo = value[6];
  schedule.menuBarTopColor = value[7];
  schedule.menuBarBottomColor = value[8];
  schedule.menuTextColor = value[9];
  schedule.activeMenuColor = value[10];
  schedule.menuToggleColor = value[11];
  schedule.menuToggleLineColor = value[12];
  schedule.jumbotronColorFrom = value[13];
  schedule.jumbotronColorTo = value[14];
  schedule.surfaceColorFrom = value[15];
  schedule.surfaceColorTo = value[16];
  schedule.surfaceTextColor = value[17];
  schedule.buttonColorFrom = value[18];
  schedule.buttonColorTo = value[19];
  schedule.buttonTextColor = value[20];
  schedule.buttonBorderColor = value[21];
  schedule.linkColor = value[22];
  schedule.errorColor = value[23];
  schedule.nightModeColor = value[24];
  schedule.containerColorFrom = value[25];
  schedule.containerColorTo = value[26];
  schedule.containerTextColor = value[27];
  schedule.containerShadowColor = value[28];
  schedule.containerExplanationColor = value[29];
}; 

export const InitialColorSchedule: IColorSchedule = {
  headerColorFrom: "lightblue",
  headerColorTo: "white",
  headerTextColor: "black",
  iconColor: "rgb(200,0,0)",
  icon2Color: "black",
  menuBarColorFrom: "darkgray",
  menuBarColorTo: "black",
  menuBarTopColor: "lightgray",
  menuBarBottomColor: "black",
  menuTextColor: "white",
  activeMenuColor: "rgb(200,0,0)",
  menuToggleColor: "gray",
  menuToggleLineColor: "white",
  jumbotronColorFrom: "gray", //"gray",
  jumbotronColorTo: "white",
  surfaceColorFrom:  "white", // #f5f9ff", //"white",
  surfaceColorTo:  "white",
  surfaceTextColor:  "black",
  buttonColorFrom: "black",
  buttonColorTo: "darkgray",
  buttonTextColor: "white",
  buttonBorderColor: "gray",
  linkColor: "rgb(200,0,0)",
  errorColor: "rgb(200,0,0)",
  nightModeColor: "yellow",
  containerColorFrom: "red",
  containerColorTo: "rgb(200,0,0)",
  containerTextColor: "white",
  containerShadowColor: "gray",
  containerExplanationColor: "black",
};

export const DayLightColorSchedule: IColorSchedule = {
    headerColorFrom: "#4691f6", //"black", //#4691f6", //lightblue",
    headerColorTo: "lightblue", //white",
    headerTextColor: "black", //black",
    iconColor: "red", 
    icon2Color: "black",
    menuBarColorFrom: "gray",
    menuBarColorTo: "darkgray",
    menuBarTopColor: "lightgray",
    menuBarBottomColor: "black",
    menuTextColor: "white",
    activeMenuColor: "red",
    menuToggleColor: "gray",
    menuToggleLineColor: "white",
    jumbotronColorFrom: "lightblue", //gray",
    jumbotronColorTo: "white",
    surfaceColorFrom:  "white",
    surfaceColorTo:  "white",
    surfaceTextColor:  "black",
    buttonColorFrom: "black",
    buttonColorTo: "darkgray",
    buttonTextColor: "white",
    buttonBorderColor: "gray",
    linkColor: "red",
    errorColor: "red",
    nightModeColor: "yellow",
    containerColorFrom: "#4691f6",
    containerColorTo: "lightblue",
    containerTextColor: "white",
    containerShadowColor: "gray",
    containerExplanationColor: "black",
  };

export const NightColorSchedule: IColorSchedule = {
  headerColorFrom: "black",
  headerColorTo: "#0f0f0f",
  headerTextColor: "white",
  iconColor: "rgb(200,0,0)",
  icon2Color: "black",
  menuBarColorFrom: "darkgray",
  menuBarColorTo: "black",
  menuBarTopColor: "lightgray",
  menuBarBottomColor: "darkgray",
  menuTextColor: "white",
  activeMenuColor: "rgb(200,0,0)",
  menuToggleColor: "gray",
  menuToggleLineColor: "white",
  jumbotronColorFrom: "darkgray",
  jumbotronColorTo: "black",
  surfaceColorFrom:  "gray",
  surfaceColorTo:  "darkgray",
  surfaceTextColor:  "black",
  buttonColorFrom: "black",
  buttonColorTo: "darkgray",
  buttonTextColor: "white",
  buttonBorderColor: "gray",
  linkColor: "darkred", //rgb(200,0,0)",
  errorColor: "rgb(200,0,0)",
  nightModeColor: "yellow",
  containerColorFrom: "#4691f6",
  containerColorTo: "darkblue",
  containerTextColor: "lightgray",
  containerShadowColor: "black",
  containerExplanationColor: "black",
};

// Actions/Reducer

export enum ColorScheduleActionType {
  CHANGE_COLORS = 'CHANGE_COLORS'
}

export type ColorScheduleAction = {
  type: ColorScheduleActionType;
  payLoad?: any;
}

export const colorScheduleReducer = (colorSchedule: IColorSchedule, action: ColorScheduleAction): IColorSchedule => {
  const newColorSchedule = {...colorSchedule};
  colorScheduleReducerAction(newColorSchedule, action);
  return newColorSchedule;
}

const colorScheduleReducerAction = (colorSchedule: IColorSchedule, action: ColorScheduleAction) => {
  if (action)
    switch (action.type) {
      case ColorScheduleActionType.CHANGE_COLORS: {
        setColors(colorSchedule, action.payLoad);
        break;
      }
    }
}

// Context and Provider

export interface IColorScheduleContext {
  colorSchedule: IColorSchedule
  dispatch: React.Dispatch<ColorScheduleAction>;
}

export const ColorScheduleContext: Context<IColorScheduleContext> = createContext(
  {} as IColorScheduleContext
);

export const initState: IColorSchedule =
  (storage && JSON.parse(localStorage.getItem(colorScheduleStorage) as string)) || InitialColorSchedule;

export const ColorScheduleProvider: React.FC = ({ children }) => {
  const [colorSchedule, dispatch] = useReducer(colorScheduleReducer, initState);

  useEffect(() => {
    if (storage)
      localStorage.setItem(colorScheduleStorage, JSON.stringify(colorSchedule));
  }, [colorSchedule]);

  return (
    <ColorScheduleContext.Provider
      value={{ colorSchedule, dispatch }}>
        {children}
    </ColorScheduleContext.Provider>
  );
};
