/// <reference types="react-scripts" />
import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';


export const Workspace = (props: any) => {
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
    const ref = React.useRef(null);
    const bgColor = `linear-gradient(to bottom,  ${colorSchedule.surfaceColorFrom} 0%,${colorSchedule.surfaceColorTo} 100%)`;

    useEffect(() => {
        const script = document.createElement('script');
         script.type = 'text/javascript';
         script.async = true;
         script.innerHTML = `
            calcHeight();

            window.addEventListener('scroll', calcHeight);
            window.addEventListener('resize', calcHeight);

            function calcHeight() { 
                var body = document.getElementById("body");
                var win = document.getElementById("win");

                // var barUnder = document.getElementById("barUnder");
                // var barHeight = barUnder ? barUnder.offsetHeight : 0;

                var workspace = document.getElementById("workspace");
                var wrapper_id = document.getElementById("wrapper_id");
                var content_id = document.getElementById("content_id");
                var offsetTop = wrapper_id.offsetTop > content_id.offsetTop ? wrapper_id.offsetTop : content_id.offsetTop;
                var height = (win.offsetHeight - workspace.offsetTop); // - 40);

                wrapper_id.style.top = 40 + "px";
                if (content_id.scrollHeight < height) {
                    wrapper_id.style.height = (height) + "px";
                } else {
                    wrapper_id.style.height = (content_id.scrollHeight) + "px";
                }
                if (win.offsetWidth - wrapper_id.offsetWidth < 50) {
                    if (win.className != "win-small") {
                        win.className = "win-small";
                        body.className = "body-small";
                        workspace.className = "workspace-small";
                        document.documentElement.style.background = "${bgColor}";
                    }                    
                } else { 
                    if (win.className != "Win") {
                        win.className = "win";
                        body.className = "body";
                        workspace.className = "workspace";
                        document.documentElement.style.background = "${bgColor}";
                    }
                }
            }
        `;
         (ref.current as any).appendChild(script);
    })

    return (
        <Styles>
            <div id="wrapper_id" style={{   color: colorSchedule.surfaceTextColor, 
                                            background: bgColor }}>
                <div id="content_id" ref={ref} className="content">
                    <Container className="content">
                        {props.children}
                    </Container>
                </div>
            </div>
        </Styles>
    );
};

const Styles = styled.div`
.wrapper {
    margin: 0px;
    padding: 0px;
    clear: both;
}

.content {
    vertical-align: top;
    padding: 0px;
    margin: 0px;
}

// a {
//     color: red;
// }
`;
