import React, { Context, createContext, useReducer, useEffect } from "react";
import { getLanguage, getCountry } from '../utils/cultures';

const appStorage = "AppStorage";
const storage = false;

// Properties involved

const defaultContent = "welcome";

export interface IApp {
  content: string;
  language: string;
  country: string;
}

export const DEFAULT_APP: IApp = {
  content: defaultContent,
  language: getLanguage(),
  country: getCountry()
};

// Actions/Reducer

export enum AppActionType {
  CHANGE_CONTENT = 'CONTENT',
  CHANGE_LANGUAGE = 'LANGUAGE',
  CHANGE_COUNTRY = 'COUNTRY'
}

export type AppAction = {
  type: AppActionType;
  payLoad?: any;
}

export const appReducer = (app: IApp, action: AppAction | AppAction[]): IApp => {
  const newApp = {...app};
  if (Array.isArray(action)) {
    action.forEach((subAction) => {
      appReducerAction(newApp, subAction);
    });
  } else {
      appReducerAction(newApp, action);
  }
  return newApp;
}

const appReducerAction = (app: IApp, action: AppAction) => {
  if (action)
    switch (action.type) {
      case AppActionType.CHANGE_CONTENT: {
        app.content = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_LANGUAGE: {
        app.language = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_COUNTRY: {
        app.country = action.payLoad;
        break;
      }
    }
}

// Context and Provider

export interface IAppContext {
  app: IApp
  dispatch: React.Dispatch<AppAction | AppAction[]>;
}

export const AppContext: Context<IAppContext> = createContext(
  {} as IAppContext
);

export const initState: IApp =
  (storage && JSON.parse(localStorage.getItem(appStorage) as string)) || DEFAULT_APP;

export const AppProvider: React.FC = ({ children }) => {
  const [app, dispatch] = useReducer(appReducer, initState);

  useEffect(() => {
    if (storage)
      localStorage.setItem(appStorage, JSON.stringify(app));
  }, [app]);

  return (
    <AppContext.Provider
      value={{ app, dispatch }}>
        {children}
    </AppContext.Provider>
  );
};