import i18next from 'i18next';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { HomeIcon } from '../icons/HomeIcon';
import { useNavigate, useParams } from "react-router-dom";
import { AppActionType, AppContext } from '../providers/AppProvider';
import { ColorScheduleActionType, ColorScheduleContext, ColorScheduleProvider, DayLightColorSchedule, NightColorSchedule, colorScheduleStorage, getColors } from '../providers/ColorScheduleProvider';
import { isMobile } from 'react-device-detect';
import { MenuItem, IMenuItem } from '../utils/menuitem';
import { fallbackLanguage, getLanguage } from '../utils/cultures';
import { SunIcon } from '../icons/SunIcon'
import { MoonIcon } from '../icons/MoonIcon'
import { display } from '../utils/logging';

const Navigator = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const appContext = useContext(AppContext);
    const colorContext = useContext(ColorScheduleContext);
    const colorSchedule = colorContext.colorSchedule;
    const languages = Object.keys(i18next.services.resourceStore.data);
    let language = localStorage.getItem("language");

    if (!language) {
      const browserLang = getLanguage();
      if (languages.includes(browserLang))
        language = browserLang;
      else
        language = fallbackLanguage;
    }
      
    const onLanguageClick = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
        console.log(`Navigate Goto /${appContext.app.content}/${language}`);
        console.log(`Language to storage: ${language}`);
        navigate(`/${appContext.app.content}/${language}`, { replace: false });
    }  
    let i = 0;
    let delimiter = (<span key={"delim_" + i} />);

    const home = `intro`;
    const welcome = `welcome`;
    const vision = `vision`;
    const contact = `contact`;
    const about = `about`;

    const menuItems: IMenuItem[] = [ //new MenuItem(home, "Menu.Home", <HomeIcon color={colorSchedule.menuTextColor}/>),
                                     new MenuItem(welcome, "Menu.Welcome"),
                                     new MenuItem(vision, "Menu.Vision"),
                                     new MenuItem(contact, "Menu.Contact"),
                                     new MenuItem(about, "Menu.About") ];

    let [ isToggled, setToggled ] = useState(false);
    let [ isExpanded, setExpanded ] = useState(false);

    let nightMode = localStorage.getItem("nightMode") == "true";

    const ref = React.useRef(null);

    const toggleColorStyle = { color: colorSchedule.menuToggleLineColor, backgroundColor: colorSchedule.menuToggleColor };

    const getMenuItemColorStyle = (content: string, isExpanded: boolean) => {
        return {
            color: colorSchedule.menuTextColor,
            borderColor: content === appContext.app.content ? colorSchedule.activeMenuColor : "transparent" }
    }

    const getClassName = (content: string, isExpanded: boolean): string => {
        if (isExpanded && (content === appContext.app.content)) 
             return "nav-toggled-invisible-link"
        else if (isExpanded) 
            return "nav-toggled-link collapsed"
        else if (content === appContext.app.content)
            return "nav-link-selected"
        else
            return "nav-link-unselected"
    }

    const onToggle = (toggled: boolean) => {
        setToggled(toggled);
        if (toggled) {
            setExpanded(true);
        } else {
            onToggleEnd();
            // const timer = setTimeout(() => {
            //     setExpanded(false);
            // }, 150);
            // return () => clearTimeout(timer);
        }
    }

    const onClick = (content: string): void => {
        if (content !== appContext.app.content) {
            appContext.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            console.log(`Navigate Goto /${content}/${i18n.language}`);
            setToggled(false);
            if (isExpanded) {
                onToggleEnd();
            }
            navigate(`/${content}/${i18n.language}`, { replace: false });
        }
    }

    const onToggleEnd = (): void => {
        setTimeout(() => {
            if (isMobile) {
                // Execute Workspace-function to recalculate
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.innerHTML = `
                calcHeight();
                `;
                (ref.current as any).appendChild(script);
            }
            setExpanded(false);
        }, 350);
    }

    const setColors = (): void => {
        // const colors = getColors(NightColorSchedule);
        // colorContext.dispatch({ type: ColorScheduleActionType.CHANGE_COLORS, payLoad: colors});
        // localStorage.setItem("colorSchedule", JSON.stringify(colors));
        // localStorage.setItem("nightMode", "true");
        let nightMode = localStorage.getItem("nightMode") == "true";
        // const colorString = localStorage.getItem("colorSchedule");
        // if (colorString) {
        //   const colors: string[] = JSON.parse(colorString);
        //   setColors(colorSchedule, colors);
        // }
        nightMode = !nightMode;
        const colors = getColors(nightMode ? NightColorSchedule : DayLightColorSchedule); 
        localStorage.setItem("nightMode", nightMode ? "true" : "false");
        colorContext.dispatch({ type: ColorScheduleActionType.CHANGE_COLORS, payLoad: colors});
        //appContext.dispatch({ type: AppActionType.CHANGE_CONTENT, payLoad: appContext.app.content});
        navigate(`/${appContext.app.content}/${language}`, { replace: false });
    }

    useEffect(() => {
        const onResize = () => {
            if (isToggled) 
                onToggle(false);
        }

        window.addEventListener('resize', onResize);
        return () => {
            //console.log("Removing resize listener");
            window.removeEventListener("resize", onResize);
        };        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isToggled])

    useLayoutEffect(() => {
        setToggled(false);
        if (isExpanded) {
            onToggleEnd();
        }
    }, [appContext.app.content]);

    return (
        <Styles>
            <Navbar expanded={isToggled} ref={ref} onToggle={onToggle} expand="lg" className='nav-bar'>
                <Navbar.Toggle as="div" aria-controls="basic-navbar-nav" style={toggleColorStyle} className="navbar-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="">
                        {menuItems.map((menuItem: IMenuItem) => {
                            return (
                                <Nav.Item key={menuItem.name}><Nav.Link style={getMenuItemColorStyle(menuItem.name, isExpanded)} 
                                                    className={getClassName(menuItem.name, isExpanded)} 
                                                    href="" onClick={() => onClick(menuItem.name)}>
                                    {menuItem.icon ? menuItem.icon : (menuItem.translationKey ? t(menuItem.translationKey) : "")}
                                </Nav.Link></Nav.Item>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>                
                <div style={isExpanded ? {display: "none"} : {}} className="languagebar">
                    <a className='nightmode' title={nightMode ? t("Colors.SwitchToDayMode") : t("Colors.SwitchToNightMode")} onClick={() => setColors()}>{nightMode ? <SunIcon color={colorSchedule.nightModeColor}/> : <MoonIcon color={colorSchedule.nightModeColor}/>}</a>
                    {languages.map((lang: string) => {
                        let display = (<span key={"span_" + lang} className="language"><button style={{color: colorSchedule.menuTextColor}} onClick={() => onLanguageClick(lang)} className="languagebar">{lang.toUpperCase()}</button></span>);
                        if (lang === i18n.language)
                            display = (<span key={"span_" + lang} style={{borderColor: colorSchedule.activeMenuColor}} className="activelanguage">{lang.toUpperCase()}</span>);
                        if (i !== 0) {
                            delimiter = (<span key={"delim_" + i}>&nbsp;|&nbsp;</span>)
                        }
                        i++;
                        return (
                           <span key={"delim_span_" + lang}>{delimiter}{display}</span>
                        )
                    })}
                </div>
            </Navbar>
        </Styles>)
}

export default Navigator;

const Styles = styled.nav`
.nightmode {
    padding-right: 15px;
    cursor: pointer;
}

.languagebar {
    white-space: nowrap !important;
    height: inherit;
}

a.languagebar {
}

button.languagebar {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border-color: transparent;
    border: 0px; 
}

span.language {
    padding: 0px !important;
    margin: 0px !important;
    
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
    border-color: transparent;
}}

span.activelanguage {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

    cursor: default;
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
}}

span.delimiter {
    white-space: nowrap !important;
    padding-top: 6px;
}}

a.nav-link {
    text-decoration: none;
}

.nav-link {
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 2px !important;
    margin-top: 10px !important;
    margin-right: 25px !important;
    margin-bottom: 10px !important;

    border: 0px;
    border-bottom: 3px;
    border-style: solid;
    border-color: transparent;
}

.nav-link:hover {   
    border: 0px;    
    border-bottom: 3px;
    border-style: solid;
    border-color: transparent;
}

.nav-link-selected {
    text-decoration: none;
    cursor: default;
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
}

.nav-link-selected:hover {
    text-decoration: none !important;
    cursor: default;
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
}

.nav-bar {
    margin: 0px;
    padding: 0px;
}

.navbar-toggle {
    border: 0px;
}

.nav-toggled-link {
    text-decoration: none;
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
    border-color: transparent;
}

.nav-toggled-link: hover {
    border: 0px;
    border-bottom: 3px;
    border-style: solid;
    border-color: transparent;
}

.nav-toggled-invisible-link {
    display: none;
}
`;