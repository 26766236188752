import React, { useContext } from 'react';
import styled from 'styled-components';
import { CompagnyIcon as CompanyIcon } from '../icons/CompanyIcon';
import { CompagnyTitleIcon } from '../icons/CompagnyTitleIcon';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { SunIcon } from '../icons/SunIcon';
import { AppActionType, AppContext } from '../providers/AppProvider';

const motto = "We automate automation";

export const Header = (props: any) => {
    const appContext = useContext(AppContext);
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
    
    const changeContent = (value: string) => {
        appContext.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: value }]);     
    };

    const currentYear = new Date().getFullYear();
    const companyName = "Softex";

    return (<Styles>
        <div key="header_1" className='header-title container'>
            <div key="header_2" className="overlay"/>
            <div key="header_3" className="table">
                <div key="header_4" className="row" >
                    <div key="header_5" className="cell" >
                        <CompanyIcon color={colorSchedule.iconColor} stopColor={colorSchedule.icon2Color} height="100px" width="80px" />
                    </div>
                    <div key="header_6" className="cell" >
                        <div key="header_7" className="subtable">
                            <div key="header_8" className="row title">
                                <div key="header_9" style={{display: "table-row"}}>
                                    <CompagnyTitleIcon color={colorSchedule.iconColor} height="26px" width="200px" />
                                </div>
                            </div>
                            {/* <a onClick={() => changeContent('automateautomation')} style={{color: colorSchedule.headerTextColor, textDecoration: 'none', cursor: 'pointer'}} className="row motto">{motto}</a> */}
                            <div key="header_10" style={{color: colorSchedule.headerTextColor, cursor: 'default', textAlign: "center", fontWeight: "normal" }} className="row motto">{motto}</div>
                        </div>
                    </div>
                    <div key="header_11" className="cell" >
                        <div key="header_12" style={{display: "table-cell", color: `${colorSchedule.headerTextColor}`, whiteSpace: "nowrap", fontSize: "small", textAlign: "left" }}>
                            &copy;{currentYear} {companyName} - All rights reserved
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Styles>)
}

const Styles = styled.div`
    .header-title {
        padding: 0px;
        margin: 0px;
       // margin-top: 10px;
        background-color: transparent;
    }
    .table {
        display: table;
        width: min-content;
        padding: 0px;
        margin: 0px;
    }
    .row {
        text-align: center;
        display: table-row;
        padding: 0px;
        margin: 0px;
    }
    .title {
        vertical-align: bottom;
        width: fit-content;
    }
    .motto {
        font-size: 17px;
        line-height: 34px;
    }
    .cell {
        padding: 0px;
        margin: 0px;
        display: table-cell;
        vertical-align: top;
    }
    .subtable {
        display: table;
        padding: 0px;
        margin: 0px;
        margin-top: 4px;
    }
    .nightmode {
        flow: right; display: flex;
    }    
    .jumbo {
        background-color: transparent;
        position: relative;
        padding: 0px;
        margin: 0px;
        margin-left: 0px;
        vertical-align: top;
        width: 100%;
    }

    .overlay {
        opacity: 0;
        position: absolute;
        margin: 0px;
        top: 0; left: 0; bottom: 0; right: 0;
        z-index: -1;
    }
`;
