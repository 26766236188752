const resources = {
    en: {
      translation: {
        Menu: {
          Home: "Home",
          Welcome: "Welcome",
          Vision: "Vision",
          About: "About",
          Contact: "Contact",
        },
        WeAutomateAutomation: {
          SoftwareAnalyst: {
            Name: "Software Analyst",
            Items: [
              "Creates data or class models",
              "Models can be linked or merged",
              "Each element can be tagged to influence the generation process",
              "Each element can exist in one or more scopes: GUI, API, Database"
            ]
          },
          SolutionArchitect: {
            Name: "Solution Architect",
            Items: [
              "Implements an application pattern",
              "Creates architectures using packages, folders, templates and scripts",
              "Each architecture-element can be tagged",
              "Templates handle models"
            ]
          },
          ForwardEngineer: {
            Name: "Forward Engineer",
            Items: [
              "Generates an application by selecting and combining an architecture and a model",
              "Templates can be set to automatically override files",
              "Templates are conditionally executed using tags",
              "External folders can be copied into the application conditially"
            ]
          },
          ReverseEngineer: {
            Name: "Reverse Engineer",
            Items: [
              "A extendable plugin system to import or sync a model",
              "SQL Server plugin"
            ]
          }
        },
        Colors: {
          Scheme: "Color scheme",
          SwitchToDayMode: "Switch to daymode",
          SwitchToNightMode: "Switch to nightmode",
        },
        Contact: {
          Title: "Contact",
          Name: "Name",
          Email: "E-mail",
          Message: "Message",
          Errors: {
            "name-is-required": "Name is required",
            "email-is-required": "E-mail is required",
            "email-invalid-expression": "E-mail is invalid",
            "message-is-required": "Message is required"
          },
        },
        Navigator: {title: "Menubar"},
        Content: "Content",
        TranslatedContent: {
          softwareanalyst: {
            Items: [
              { 
                Title: "Creates models",
                Paragraph: "Registering classes, properties, methods, formulas and references/associations between classes. Our core business is the generation of working software modules and applications. We have developed the Reverse Engineer for the import of metadata from other software."
              },
              { 
                Title: "",
                Paragraph: "All meta-elements are multilingual, so the templates generate already populated Language files: the User Interfaces that we create support multilingualism"
              },
              { 
                Title: "Link or merge models",
                Paragraph: "References can be made between models, allowing models to be kept small and reused. This means, for example, smaller Apps, but also the development of Micro Services. If desired, models can nevertheless be merged for generation."
              },
              { 
                Title: "Tagged",
                Paragraph: "Each element can be tagged and each tag can be assigned a value. These tags can be used by the Solution Architect to set up templates conditionally. The templates use the tags to make various tradeoffs during generation. The design can therefore be separated from the implementation as much as possible. The scopes are an exception to this:"
              },
              { 
                Title: "Scopes: GUI, API, Database",
                Paragraph: "Nowadays, software often consists of a (separate) user interface (GUI) and/or a registration system (e.g. an RDBMS) and/or internet communication (API, e.g. in the form of WebServices). It can therefore be recorded in which scopes each element exists. In this way we create a bridge from the logical to the physical model. Example:"
              },
              { 
                Title: "",
                Paragraph: "It is of course undesirable for the history of used passwords to exist in the GUI. Just as it is undesirable for the password to exist in the database, but to go from the GUI to the API. The encrypted password again only exists in the API and in the database."
              }
            ]  
          },
          solutionarchitect: {
            Items: [
              { 
                Title: "TODO",
                Paragraph: ""
              }
            ]  
          },
          forwardengineer: {
            Items: [
              { 
                Title: "TODO",
                Paragraph: ""
              }
            ]  
          },
          reverseengineer: {
            Items: [
              { 
                Title: "TODO",
                Paragraph: ""
              }
            ]  
          },
          welcome: {
            Items: [
              { 
                Title: "Welcome",
                Paragraph: "Welcome to the Softex website. Softex is a specialist in the field of information systems and builds custom software for the internet. With our years of experience, we help you translate your early idea into the final product and support you with its use."
              },
              { 
                Title: "<goto>\"We automate automation\";weautomateautomation</goto>",
                Paragraph: "Our method is that we automate the automation itself. Softex uses automated production lines that can produce software faster and cheaper and in different models and versions. Particularly useful in situations where <italic>time-to-market</italic> is crucial. The rapid and frequent use of lifelike prototypes also makes the operation of the software visible to you at an early stage. These production lines make customization possible."
              },
              { 
                Title: "Contact",
                Paragraph: "Are you interested in Softex and one of our services? Please <goto>contact</goto> us."
              }
            ]  
          },
          vision: {
            Items: [
              { 
                Title: "Vision",
                Paragraph: "Good automation is essential for the efficiency of your company or organization. For this reason, you use standard software, e.g. Office or SAP, that already covers a broad spectrum of your business operations. For the unique and innovative character of your company standard software is not enough, that is why you look for solutions for specific processes in custom software."
              },
              { 
                Title: "",
                Paragraph: "It may be that the functionality of your current software no longer properly fits. It happens that specifications already change during construction. This could jeopardize the on time usage of the software, its quality and keeping costs within budget. Smaller functional changes can have larger technical and financial consequences. If you are involved in innovation and want to be one step ahead of the competition, the stakes are higher."
              },
              { 
                Title: "Economic climate",
                Paragraph: "Perhaps you currently have less room for these investments. However, the need for this does not decrease. On the contrary, you now want or need to profile yourself even more, become even more competitive. Your business operations change. Your information needs to change. Your software must change with it."
              },
              { 
                Title: "Solution",
                Paragraph: "Our solution is cheaper, faster and more efficient software production through <goto>automatic automation;weautomateautomation</goto> using automated production lines."
              }
            ]  
          },
          weautomateautomation: {
            Items: [
              { 
                Title: "\"We automate automation\"",
                Paragraph: "Our method is that we automate the automation itself. Softex uses automated production lines, these production lines make customization possible. Within our tools we have the following roles:"
              }
            ]  
          },
          contact: {
            Items: [
              { 
                Title: "Contact (TODO: service not yet implemented)",
                Paragraph: "Complete this form to get in touch:"
              }
            ]  
          },
          about: {
            Items: [
              { 
                Title: "About",
                Paragraph: "This website is (being) developed in REACT."
              }
            ]  
          }
        } 
      },
    },
    de: {
      translation: {
        Menu: {
          Home: "Home",
          Welcome: "Wilkommen",
          Vision: "Vision",
          About: "Über",
          Contact: "Kontakt",
        },
        WeAutomateAutomation: {
          SoftwareAnalyst: {
            Name: "Software Analyst",
            Items: [
              "Erstellt Daten- oder Klassenmodelle",
              "Modelle können verknüpft oder zusammengeführt werden",
              "Jedes Element kann mit Tags versehen werden, um den Generierungsprozess zu beeinflussen",
              "Jedes Element kann in einem oder mehreren Bereichen vorhanden sein: GUI, API, Datenbank"
            ]
          },
          SolutionArchitect: {
            Name: "Solution Architect",
            Items: [
              "Implementiert ein Applikationsmuster",
              "Erstellt Architekturen mithilfe von Pakete, Mappen, Schablonen und Skripts",
              "Jedes Architekturelement kann mit Tags versehen werden",
              "Schablonen verarbeiten Modelle"
            ]
          },
          ForwardEngineer: {
            Name: "Forward Engineer",
            Items: [
              "Erstellt eine Applikation durch Auswahl und Kombination einer Architektur und eines Modells",
              "Schablonen können so eingestellt werden, dass Dateien automatisch überschrieben werden",
              "Schablonen werden bedingt mithilfe von Tags ausgeführt",
              "Externe Mappen können bedingt in die Applikation kopiert werden"
            ]
          },
          ReverseEngineer: {
            Name: "Reverse Engineer",
            Items: [
              "Ein erweiterbares Plugin-System zum Importieren oder Synchronisieren eines Modells",
              "SQL Server plugin"
            ]
          }
        },
        Colors: {
          Scheme: "Farbschema",
          SwitchToDayMode: "Zum Tagmodus",
          SwitchToNightMode: "Zum Nachtmodus",                
        },
        Contact: {
          Title: "Kontakt",
          Name: "Name",
          Email: "E-mail",
          Message: "Botschaft",
          Errors: {
            "name-is-required": "Name ist verpflichtet",
            "email-is-required": "E-mail ist verpflichtet",
            "email-invalid-expression": "E-mail ist falsch",
            "message-is-required": "Botschaft ist verpflichtet"
          },
        },
        Navigator: {title: "Menüleiste"},
        Content: "Inhalt",
        TranslatedContent: {
          softwareanalyst: {
            Items: [
              { 
                Title: "Erstellt Modelle",
                Paragraph: "Registrieren von Klassen, Eigenschaften, Methoden, Formeln und Referenzen/Assoziationen zwischen Klassen. Unser Kerngeschäft ist die Erstellung funktionsfähiger Softwaremodule und Anwendungen. Für den Import von Metadaten aus anderer Software haben wir den <goto>Reverse Engineer;reverseengineer</goto> entwickelt."
              },
              { 
                Title: "",
                Paragraph: "Alle Metaelemente sind mehrsprachig, sodass die Schablonen bereits ausgefüllte Sprachdateien generieren: die von uns erstellten User Interfaces unterstützen die Mehrsprachigkeit"
              },
              { 
                Title: "Modelle verknüpfen oder zusammenführen",
                Paragraph: "Es können Referenzen zwischen Modellen hergestellt werden, sodass Modelle klein gehalten und wiederverwendet werden können. Das bedeutet beispielsweise kleinere Apps, aber auch die Entwicklung von Micro Services. Auf Wunsch können Modelle dennoch zur Generierung zusammengeführt werden."
              },
              { 
                Title: "Mit Tags versehen",
                Paragraph: "Jedes Element kann mit Tags versehen und jedem Tag kann ein Wert zugewiesen werden. Diese Tags können vom <goto>Solution Architect;solutionarchitect</goto> verwendet werden, um Schablonen bedingt bereitzustellen. Die Schablonen können die Tags verwenden, um während der Generierung andere Überlegungen anzustellen. Der Entwurf kann daher weitestgehend von der Umsetzung getrennt werden. Eine Ausnahme hiervon bilden die Bereiche:"
              },
              { 
                Title: "Bereiche: GUI, API, Datenbank",
                Paragraph: "Heutzutage besteht Software häufig aus einer (separaten) User Interface (GUI) und/oder einem Registrierungssystem (z. B. einem RDBMS) und/oder einer Internetkommunikation (API, z. B. in Form von WebServices). Somit kann erfasst werden, in welchen Bereichen jedes Element vorhanden ist. Damit schlagen wir eine Brücke vom logischen zum physikalischen Modell. Beispiel:"
              },
              { 
                Title: "",
                Paragraph: "Es ist natürlich unerwünscht, dass die Historie der verwendeten Passwörter in der GUI vorhanden ist. Ebenso ist es unerwünscht, dass das Passwort in der Datenbank existiert, sondern von der GUI zur API gelangt. Das verschlüsselte Passwort existiert wiederum nur in der API und in der Datenbank."
              }
            ]  
          },
          welcome: {
            Items: [
              { 
                Title: "Wilkommen",
                Paragraph: "Willkommen auf der Softex-Website. Softex ist Spezialist im Bereich Informationssysteme und baut kundenspezifische Software unter anderem für das Internet. Mit unserer langjährigen Erfahrung helfen wir Ihnen, Ihre frühe Idee in das Endprodukt umzusetzen und unterstützen Sie bei der Nutzung."
              },
              { 
                Title: "<goto>\"We automate automation\";weautomateautomation</goto>",
                Paragraph: "Unsere Methode besteht darin, dass wir die Automatisierung selbst automatisieren. Softex nutzt automatisierte Produktionslinien, die Software schneller und kostengünstiger und in verschiedenen Modellen und Versionen produzieren können. Besonders nützlich in Situationen, in denen <italic>Time-to-Market</italic> entscheidend ist. Durch den schnellen und häufigen Einsatz lebensechter Prototypen wird zudem die Funktionsweise der Software frühzeitig für Sie sichtbar. Diese Produktionslinien ermöglichen individuelle Anpassungen."
              },
              { 
                Title: "Kontakt",
                Paragraph: "Sie interessieren sich für Softex und eine unserer Dienstleistungen? Bitte <goto>kontaktieren;contact</goto> Sie uns."
              }
            ]  
          },
          vision: {
            Items: [
              { 
                Title: "Vision",
                Paragraph: "Eine gute Automatisierung ist für die Effizienz Ihres Unternehmens oder Ihrer Organisation von entscheidender Bedeutung. Aus diesem Grund nutzen Sie Standardsoftware, z. B. Office oder SAP, die bereits ein breites Spektrum Ihrer Geschäftsabläufe abdeckt. Für den einzigartigen und innovativen Charakter Ihres Unternehmens reicht Standardsoftware nicht aus, Deshalb suchen Sie nach Lösungen für bestimmte Prozesse in kundenspezifischer Software."
              },
              { 
                Title: "",
                Paragraph: "Es kann sein, dass die Funktionalität Ihrer aktuellen Software nicht mehr richtig passt. Es kommt vor, dass sich Spezifikationen während des Baus ändern. Dies könnte den termin- und budgetgerechten Einsatz der Software oder deren Qualität gefährden. Kleinere funktionale Änderungen können größere technische und finanzielle Folgen haben. Wenn Sie an Innovationen beteiligt sind und der Konkurrenz einen Schritt voraus sein wollen, steht mehr auf dem Spiel."
              },
              { 
                Title: "Wirtschaftslage",
                Paragraph: "Vielleicht haben Sie derzeit weniger Spielraum für diese Investitionen. Der Bedarf hierfür nimmt jedoch nicht ab. Im Gegenteil, Sie wollen oder müssen sich jetzt noch stärker profilieren, noch wettbewerbsfähiger werden. Ihre Geschäftsabläufe ändern sich. Ihr Informationsbedarf ändert sich. Ihre Software muss sich damit ändern."
              },
              { 
                Title: "Lösung",
                Paragraph: "Unsere Lösung ist eine kostengünstigere, schnellere und effizientere Softwareproduktion durch <goto>automatische Automatisierung;weautomateautomation</goto> mithilfe automatisierter Produktionslinien.      "
              }
            ]  
          },
          weautomateautomation: {
            Items: [
              { 
                Title: "\"We automate automation\"",
                Paragraph: "Unsere Methode besteht darin, dass wir die Automatisierung selbst automatisieren. Softex nutzt automatisierte Produktionslinien, diese Produktionslinien ermöglichen individuelle Anpassungen. Innerhalb unserer Tools haben wir die folgenden Rollen:"
              }
            ]  
          },
          contact: {
            Items: [
              { 
                Title: "Kontakt (TODO: Dienst noch nicht implementiert)",
                Paragraph: "Füllen Sie dieses Formular aus, um Kontakt aufzunehmen:"
              }
            ]  
          },
          about: {
            Items: [
              { 
                Title: "Über",
                Paragraph: "Diese Website wird/wird in REACT entwickelt."
              }
            ]  
          }
        } 
      },
    },
    nl: {
      translation: {
        Menu: {
          Home: "Home",
          Welcome: "Welkom",
          Vision: "Visie",
          About: "Over",
          Contact: "Contact",
        },
        WeAutomateAutomation: {
          SoftwareAnalyst: {
            Name: "Software Analyst",
            Items: [
              "Creëert gegevens- of klassemodellen",
              "Modellen kunnen worden gekoppeld of samengevoegd",
              "Elke element kan worden getagged om het generatieproces te beïnvloeden",
              "Elk element kan in een of meer scopes bestaan: GUI, API, Database"
            ]
          },
          SolutionArchitect: {
            Name: "Solution Architect",
            Items: [
              "Implementeert een applicatie pattern",
              "Creëert architecturen met behulp van packages, mappen, sjablonen en scripts",
              "Elk architectuurelement kan worden getagd",
              "Sjablonen verwerken modellen"
            ]
          },
          ForwardEngineer: {
            Name: "Forward Engineer",
            Items: [
              "Genereert een applicatie door een architectuur en een model te selecteren en te combineren",
              "Sjablonen kunnen worden ingesteld om bestanden automatisch te overschrijven",
              "Sjablonen worden voorwaardelijk uitgevoerd met behulp van tags",
              "Externe mappen kunnen voorwaardelijk naar de applicatie worden gekopieerd"
            ]
          },
          ReverseEngineer: {
            Name: "Reverse Engineer",
            Items: [
              "Een uitbreidbaar plug-insysteem om een model te importeren of te synchroniseren",
              "SQL Server plug-in"
            ]
          }
        },
        Colors: {
          Scheme: "Kleurenschema",
          SwitchToDayMode: "Switch naar dagmodus",
          SwitchToNightMode: "Switch naar nachtmodus",
        },
        Contact: {
          Title: "Contact",
          Name: "Naam",
          Email: "E-mail",
          Message: "Boodschap",
          Errors: {
            "name-is-required": "Naam is verplicht",
            "email-is-required": "E-mail is verplicht",
            "email-invalid-expression": "E-mail is onjuist",
            "message-is-required": "Boodschap is verplicht"
          },
        },
        Navigator: {title: "Menubalk"},
        Content: "Inhoud",
        TranslatedContent: {
          Name: "Software Analyst",
          softwareanalyst: {
            Items: [
              { 
                Title: "Creëert modellen",
                Paragraph: 
                  "Het vastleggen van klasses, eigenschappen, methodes, formules en de referenties/associaties tussen klasses. " + 
                  "Onze core-business is de generatie van werkende softwaremodules en -applicaties. " +
                  "Voor de import van metadata uit andere software hebben we de <goto>Reverse Engineer;reverseengineer</goto> ontwikkeld."
              },
              { 
                Title: "",
                Paragraph: 
                  "Alle meta-elementen zijn meertalig, waardoor de sjablonen reeds gevulde Taal-bestanden genereren: de User Interfaces die wij maken ondersteunen meertaligheid "
              },
              { 
                Title: "Modellen koppelen of samenvoegen",
                Paragraph: 
                  "Er kunnen referenties gemaakt worden tussen modellen, waardoor modellen klein gehouden kunnen worden en kunnen worden hergebruikt. "+
                  "Dit betekent bijv. kleinere Apps, maar ook de ontwikkeling van Micro Services. " + 
                  "Indien gewenst kunnen modellen niettemin voor generatie worden samengevoegd."
              },
              { 
                Title: "Getagd",
                Paragraph:
                  "Elk element kan worden getagd en aan elke tag kan een waarde worden toegekend. " +
                  "Deze tags kunnen door de <goto>Solution Architect;solutionarchitect</goto> worden gebruikt om sjablonen conditioneel in te zetten. "+
                  "De sjablonen gebruiken de tags om verschillende afwegingen te maken tijdens het genereren. " +
                  "Het ontwerp kan daardoor zoveel mogelijk worden losgekoppeld van de implementatie. De scopes zijn hierop een uitzondering:"
              },
              { 
                Title: "Scopes: GUI, API, Database",
                Paragraph: 
                  "Software bestaat tegenwoordig vaak uit een (losse) gebruikersinterface (GUI) en/of een registratiesysteem (bijv. een RDBMS) en/of internetcommunicatie (API bijv. in de vorm van WebServices). " +
                  "Van elke element kan daarom worden vastgelegd in in welke scopes deze bestaat. Op deze manier maken we een brug van het logische naar het fysieke model. Voorbeeld:"
              },
              { 
                Title: "",
                Paragraph: 
                  "Het is natuurlijk ongewenst dat de historie van gebruikte passwords in de GUI bestaat. " +
                  "Net zoals het ongewenst is dat het wachtwoord in de database bestaat, maar wel vanaf de GUI naar de API gaat. Het versleutelde wachtwoord bestaat weer alleen in de API en in database."
              }
            ]  
          },
          welcome: {
            Items: [
              { 
                Title: "Welkom",
                Paragraph: "Welkom op de website van Softex. Softex is specialist op het gebied van informatiesystemen en bouwt o.a. maatwerk software voor het internet. Met onze jarenlange ervaring helpen we uw prille idee te vertalen naar het uiteindelijke product en ondersteunen we u bij het gebruik."
              },
              { 
                Title: "<goto>\"We automate automation\";weautomateautomation</goto>",
                Paragraph: "Onze methode is dat wij de automatisering zelf automatiseren. Softex maakt gebruik van geautomatiseerde productielijnen die software sneller en goedkoper en in verschillende modellen en uitvoeringen kunnen produceren. Ondermeer handig in situaties waar <italic>time-to-market</italic> cruciaal is. Het snel en veelvuldig inzetten van levensechte prototypes maakt de werking van de software bovendien al in een vroeg stadium voor u zichtbaar. Deze productielijnen maken maatwerk mogelijk."
              },
              { 
                Title: "Contact",
                Paragraph: "Bent u geïnteresseerd in Softex en een van onze diensten? Neem dan <goto>contact</goto> met ons op."
              }
            ]  
          },
          vision: {
            Items: [
              { 
                Title: "Visie",
                Paragraph: "Een goede automatisering is essentieel voor de doelmatigheid van uw bedrjf of organisatie. Om die reden maakt u gebruik van standaard software, bv. Office of SAP, dat reeds een breed spectrum van uw bedrijfsvoering afdekt. Voor het unieke en innoverende karakter van uw bedrijf heeft u niet genoeg aan standaard software, daarom zoekt u voor specifieke processen de oplossing in maatwerk software."
              },
              { 
                Title: "",
                Paragraph: "Het kan zijn dat de functionaliteit van uw huidige software niet meer goed aansluit. Het komt voor dat specificaties nog tijdens de bouw wijzigen. Het op tijd en binnen budget in gebruik nemen van de software of de kwaliteit ervan kan daarmee in gevaar komen. Kleinere functionele wijzigingen kunnen grotere, technische en financiële gevolgen hebben. Als u met innovatie bezig bent, de concurrentie een stap voor wilt zijn, staat er meer op het spel."
              },
              { 
                Title: "Economisch klimaat",
                Paragraph: "Misschien is er momenteel ook bij u minder ruimte voor deze investeringen. Het is echter niet zo dat de behoefte hieraan afneemt. Integendeel zelfs, u wilt of moet zich nu nog meer profileren, nog competetiever worden. Uw bedrijfsvoering verandert. Uw informatiebehoefte verandert. Uw software moet mee veranderen."
              },
              { 
                Title: "Oplossing",
                Paragraph: "Onze oplossing is een goedkopere, snellere en efficiëntere softwareproductie door <goto>automatisch automatiseren;weautomateautomation</goto> met behulp van geautomatiseerde productielijnen."
              }
            ]  
          },
          weautomateautomation: {
            Items: [
              { 
                Title: "\"We automate automation\"",
                Paragraph: "Onze methode is dat wij de automatisering zelf automatiseren. Softex maakt gebruik van geautomatiseerde productielijnen, deze productielijnen maken maatwerk mogelijk. Binnen onze tools hebben we de volgende rollen:"
              }
            ]  
          },
          contact: {
            Items: [
              { 
                Title: "Contact (TODO: Service nog niet geïmplementeerd)",
                Paragraph: "Vul dit formulier in om in contact te komen:"
              }
            ]  
          },
          about: {
            Items: [
              { 
                Title: "Over",
                Paragraph: "Deze website is/wordt ontwikkeld in REACT en is de opvolger van onze ASP.NET-website.<br/>" +
                  "Beide websites bestaan uit een aantal vastgeprogrammeerde pagina's, maar ook altijd uit een generieke Content-pagina."
              },
              { 
                Title: "Content",
                Paragraph: 
                  "De content willen we het liefst kunnen vervangen buiten de website om. " + 
                  "De content moet meertalig zijn, zoals dat te doen gebruikelijk is, met een terugval-mechanisme wanneer een taal ontbreekt. " +
                  "Onze oude ASP.NET-website maakt gebruik van XML-bestanden en XSLT, Microsoft-variant. en dat is nu geen optie meer. " +
                  "De juiste oplossing leek ons het klaarzetten van  HTML-bestanden. " +
                  "Op zich werkt dat wel, maar dan ontkom je eigenlijk niet aan het gebruik van 'dangerouslySetInnerHTML'. " +
                  "In de situatie van Softex is dat niet zo'n probleem, ware het niet dat locale hyperlinks bij sommige browsers tot veel onrustig geflikker leidt. De Applicatie wordt nl. weer in zijn geheel opgebouwd. " +
                  "Daarom hebben we een nieuwe Content-function ontwikkeld op basis van react i18next, waarbij de navigatie wordt afgehandeld door het component zelf, met als gevolg een veel rustigere website. " +
                  "Nadeel is dat we website opnieuw moeten neerzetten, maar in ons geval is dat zo gepiept. Voor het geval dat hebben de eerste Content-pagina nog in leven gelaten."
              },
              { 
                Title: "<goto>\"We automate automation\";weautomateautomation</goto>",
                Paragraph: 
                  "Voor de ASP.NET-website beschikken we over corresponderende architectuur in onze generator. Voor REACT hebben we helaas nog niets. " +
                  "We hebben echter wel een Angular-architectuur die precies aansluit op een NodeJS/Express/Typescript-architectuur. " +
                  "Met aansluitingen aan SQL Server, MySQL of PostgreSQL."
              }
            ]  
          }
        } 
      },
    },
  };
  
  export { resources };
  