import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { AppActionType, AppContext } from '../providers/AppProvider';
import { isMobile } from "react-device-detect";
import { fallbackLanguage } from '../utils/cultures';
import axios from 'axios';
import { ColorScheduleContext, DayLightColorSchedule, ColorScheduleProvider, IColorSchedule, NightColorSchedule } from '../providers/ColorScheduleProvider';
//import Dompirify from 'dompurify';

const contentNotFound = "notfound";

export const Content = () => {
    const colorContext = useContext(ColorScheduleContext);
    const colorSchedule = colorContext.colorSchedule;    
    const { i18n } = useTranslation();
    let { content, language } = useParams();
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [state, setState] = useState({ __html: "" });
    
    const nightMode = localStorage.getItem("nightMode");    
    
    // Check a language change from the browser's url
    if (language) {
        language = language.toLowerCase();
        // Return the languages supported:
        const languages = Object.keys(i18next.services.resourceStore.data);
        // Check whether this language is supported
        if (languages.includes(language))
            if (language !== i18n.language) {
                // This will redraw the Navigator
                i18n.changeLanguage(language);
                console.log(`Language to storage: ${language}`);
                localStorage.setItem("language", language);
            }
    } else {
        language = i18n.language;
    }
    
    if (content)
        content = content.toLowerCase();
    else
        content = appContext.app.content;
    
    const loadHTML = async(content: string, language?: string) => {
        //const publicFolder = "https://content.softex.nl"; 
        const publicFolder = ""; 
        const contentFolder = `${publicFolder}/eureka/html`;
        const mobileFolder = `${contentFolder}/mobile`;
        let files: string[] = [];

        let alternativeLanguage = fallbackLanguage;
        if (!alternativeLanguage && i18next.options.fallbackLng)
            if (typeof(i18next.options.fallbackLng) == 'string')
                alternativeLanguage = i18next.options.fallbackLng;
        if (!alternativeLanguage)
            alternativeLanguage = "en";

        if (isMobile) {
            files.push(`${mobileFolder}/${content}.${language}.html`, 
                       `${mobileFolder}/${content}.html`);
            if (language !== alternativeLanguage)
                files.push(`${mobileFolder}/${content}.${alternativeLanguage}.html`);
        }
        files.push(`${contentFolder}/${content}.${language}.html`, `${contentFolder}/${content}.html`);
        if (language !== alternativeLanguage)
            files.push(`${contentFolder}/${content}.${alternativeLanguage}.html`);
        files.push(`${contentFolder}/${contentNotFound}.${language}.html`, `${contentFolder}/${contentNotFound}.html`);
        if (language !== alternativeLanguage)
            files.push(`${contentFolder}/${contentNotFound}.${alternativeLanguage}.html`);

        for (let i = 0; i < files.length; i++) {
            let fileName = files[i];
            let html: string | undefined = '';

            await axios({
                    method: 'get',
                    url: fileName,
                    withCredentials: false,
                    // proxy: {
                    //     protocol: 'https',
                    //     host: 'content.softex.nl',
                    //     port: 9000,
                    // },                    
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods':'GET'
                    }
                })
                .then(res => {
                    html = res.data;
                })
                .catch((err: any) => {
                    //html = err;
                    if (i === files.length - 1) {
                        console.log(err);
                    }
                })
            if (html && html != "") {
                //setState({__html: Dompirify.sanitize(html)});
                setState({__html: html});
                break;
            }
        }
    }

    const changeContent = (value: string) => {
        content = value;
        //alert(value);
        navigate(`/${content}/${language}`, { replace: false });
    };

    useEffect(() => {
        if (content !== appContext.app.content) {
            appContext.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            if (window.history.state.key == null) {
                console.log(`Content Goto /${content}/${language}`);
                window.scrollTo(0, 0);          
                navigate(`/${content}/${language}`, { replace: false });
            }
        }
    })

    useEffect(() => {
        if (content) {
            loadHTML(content, language);
        }
    }, [language, content, nightMode])

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [content]);    

    const linkColor = colorSchedule.linkColor;

    return (
        <Styles linkColor={linkColor}>
            <div dangerouslySetInnerHTML={state} />            
            <div className="clear" />
            {/* <span onClick={() => changeContent('welcome')}>automatisch automatiseren</span>
            <span onClick={() => changeContent('contact')}>contact</span> */}
        </Styles>)
}

const Styles = styled.div<{ linkColor: string }>`
    .html {
        padding: 0px;
        padding-top: 25px;
        margin-left: 15px;
    }
    .box {
        min-height: 500px;  
        margin: 0px; margin-right: 25px; margin-bottom: 25px;  
        padding-bottom: 15px;
    }
    .title {
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
    }
    .main-title {
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
        //background: linear-gradient(to bottom,  darkred 0%, darkred 100%);
        //color: white;
        //height: 50px;
        //width: 200px;
        //padding-left: 10px;
        //padding-top: 10px;
        //padding-bottom: 10px;
        //text-shadow: -1px 0 black, 0 1px black, 1px 0 red, 0 -1px black;
        //box-shadow: 3px 3px 1px darkgray;
    }    
    .paragraph {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 0px;
    }
    a {
        color: ${(props) => props.linkColor};
    }    
`;
