/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as logging from "./logging";
import { defaultMessageType, ErrorCode, MessageType, setMessage } from "./messages";

export enum ObjectValidationState {
    Create = 0,
    Update = 1,
    Delete = 2
}

export class ObjectValidation {

    private list: ObjectValidationProperty[] = [];

    constructor (private obj: any, private messageType = MessageType.Code) {        
        this.obj = obj;        
        this.list = [];
        this.messageType = messageType;
    }

    getInstance(): Record<string, any> {
        if (typeof(this.obj) == "function")
            return this.obj();
        else
            return this.obj;
    }        

    setInstance(obj: any) {
        return this.obj = obj;
    } 

    get(propertyName: string | string[], states: ObjectValidationState[] = [ ObjectValidationState.Create, ObjectValidationState.Update ], reset = false): ObjectValidationProperty {
        for (let i = 0; i < this.list.length; i++) {
            const item = this.list[i];
            if (item.propertyName == propertyName) {
                if (reset)
                    item.validations.splice(0, item.validations.length);
                return item;
            }
        }    

        const validationItem = new ObjectValidationProperty(propertyName, states);        
        this.list.push(validationItem);               
        return validationItem;
    }

    private getListResult(state: ObjectValidationState, ref = false, propertyName: string | undefined): any[] {
        const result = [];
        for (let i = 0; i < this.list.length; i++) {
            const item = this.list[i];
            // Validate all properties or one single property
            if (propertyName == null || (typeof(item.propertyName) == "string" && item.propertyName == propertyName)) {
                const states = item.states;
                if (states.includes(state)) {
                    for (let j = 0; j < item.validations.length; j++) {
                        const validation = item.validations[j];
                        if (validation.states.length == 0 || validation.states.includes(state))
                            if (ref)
                                result.push({
                                    validationProperty: item, 
                                    validationAction: validation
                                });                    
                            else
                                result.push(validation.action);
                    }    
                }
            }
        }    
        return result;
    }

    private getList(state: ObjectValidationState, propertyName: string | undefined): any[] {
        return this.getListResult(state, false, propertyName);
    }

    private getListRef(state: ObjectValidationState, propertyName: string | undefined): any[] {
        return this.getListResult(state, true, propertyName);
    }

    async execute(state: ObjectValidationState, singlePropertyName: string | undefined = undefined): Promise<Record<string, any>> {
        const refs = this.getListRef(state, singlePropertyName);
        const output: Record<string, any> = {}; 
        const propResults: Record<string, any[]> = {};

        function addPropertyError(propertyName: string, code: string, message: string): void {
            let list = propResults[propertyName]; // Check whether another validationrule already led to an error for this property 
            if (list == null) {
                list = [];
                propResults[propertyName] = list; 
            }
            list.push({ // Add the code and message to the errorlist for this property
                code: code, 
                message: message });              
        }

        await Promise.all(this.getList(state, singlePropertyName))
            .then((results: any[]) => {
                for (let i = 0; i < results.length; i++) {
                    const result = results[i];
                    // Error occurred for number i
                    if (!result) {
                        // Select the validationProperty information to it
                        const ref = refs[i];
                        const propertyName = ref.validationProperty.propertyName;
                        const code = ref.validationAction.code;    
                        const message = ref.validationAction.message; 
                        
                        if (typeof(propertyName) == "string") { // Simple validation against one property
                            addPropertyError(propertyName, code, message);
                        } else { // Multiple property validation f.e. DateEnd > DateStart
                            if (Array.isArray(propertyName))
                                // If a multiproperty validation fails for one property, if fails for all of them
                                propertyName.forEach((property: string) => {
                                    addPropertyError(property, code, message);
                                });
                        }
                    }
                }

                // Merge everything together in the output
                Object.keys(propResults).forEach((key: any) => {
                    const propertyName = key;
                    const result = propResults[key];
                    let code = "";
                    let message = "";
                    result.forEach((error: any) => {                        
                        if (code != "") code += ";";
                        if (message != "") message += ";";
                        code += error.code;    
                        message += error.message;        
                    });
                    // Add the value that caused the error to it
                    output[propertyName] = {
                        value: this.getInstance()[propertyName]
                    };                        
                    setMessage(output[propertyName], code, message, defaultMessageType);
                });
            })
            .catch((err: Error) => {
                output["message"] = ErrorCode.Exception;           
                logging.error(err);
                // Log               
            });        
            return output;
    }

    notEmpty(propertyName: string, partialValidation = false): Promise<unknown> {
        const obj = this.getInstance();
        return new Promise((resolve) => {
            const value = obj[propertyName];
            if (value != null) {
                if (value.toString().trim() == "") {
                    resolve(false);
                } else {
                    resolve(true);
                }
            } else if (!partialValidation) { // Inserts and full updates
                resolve(false);
            } else {
                resolve(value !== undefined); // Partial validation
            }
        });
    }

    isAvailable(propertyName: string): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if ((value !== undefined)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    notEmptyArray(propertyName: string, create: boolean | null): Promise<unknown> {
        const value: any[] | null = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if ((value == null && create == true) || (value != null && value.length == 0))
                resolve(false);
            else
                resolve(true);
        });
    }    

    isValidSize(propertyName: string, size: number): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value == null) {
                resolve(true);
            } else if (typeof(value) == "object") {
                // Assume the referenced object is already validated, otherwise overrule all of this  
                resolve(true);
            } else if (value != null && value.toString().trim() != "") {
                resolve(value.length <= size);
            } else {            
                resolve(true);
            }
        });
    }

    isValidExpression(propertyName: string, regex: RegExp): Promise<unknown> {
        const obj = this.getInstance();
        return new Promise((resolve) => {
            const value = obj[propertyName];
            if (value != null && value.toString().trim() != "") {
                resolve(regex.test(value));
            } else {
                resolve(true);
            }
        });
    }

    isValidUrl(propertyName: string): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            try {
                new URL(value);
                resolve(true);
            } catch (err) {
                resolve(false);
            }
        });
    }

    isValidDateTime(propertyName: string): Promise<unknown> {
        let value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                value = new Date(value);
                resolve(!isNaN(value) && !isNaN(value.getFullYear()));
            } else {
                resolve(true);
            }
        });
    }

    isValidDate(propertyName: string): Promise<unknown> {
        let value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                value = new Date(value); // TODO?
                resolve(!isNaN(value) && !isNaN(value.getFullYear()));
            } else {
                resolve(true);
            }
        });
    }

    isValidTime(propertyName: string): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                resolve(true); //TO DO
            } else {
                resolve(true);
            }
        });
    }

    isValidBoolean(propertyName: string): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                resolve(true); //TO DO
            } else {
                resolve(true);
            }
        });
    }

    isValidNumber(propertyName: string, numbers: number, dec: number, signed = true): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                const regex = numericPattern(numbers, dec, signed);
                resolve(regex.test(value));
            } else {
                resolve(true);
            }
        });        
    }

    withinRange(propertyName: string, lowerLimit: any = undefined, upperLimit: any = undefined, excludeLowerLimit = false, excludeUpperLimit = false): Promise<unknown> {
        const value = this.getInstance()[propertyName];
        return new Promise((resolve) => {
            if (value != null && value.toString().trim() != "") {
                if (lowerLimit != null)
                    if (value < lowerLimit || excludeLowerLimit &&  value == lowerLimit)
                        resolve(false);
                if (upperLimit != null)
                    if (value > upperLimit || excludeUpperLimit &&  value == upperLimit)
                        resolve(false);
            }
            resolve(true);
        });
    }
}

export class ObjectValidationProperty {
 
    readonly validations: ObjectValidationAction[] = [];

    constructor (readonly propertyName: string | string[], readonly states: ObjectValidationState[]) {
        this.validations = [];
    }

    validate(action: Promise<unknown>, code: string, message: string, states: ObjectValidationState[] = []): ObjectValidationProperty {
        const index = this.validations.findIndex(validation => validation.code == code)
        const validationAction = new ObjectValidationAction(action, code, message, states);

        if (index == -1) {
            this.validations.push(validationAction);
        } else {
            this.validations[index] = validationAction; // We can override defaults when customizing 
        } 

        return this; // So we can add a number of tests using dot notation.
    }
}

class ObjectValidationAction {
    constructor (readonly action: Promise<unknown>, readonly code: string, readonly message: string, readonly states: ObjectValidationState[] = []) {
    }
}

function numericPattern(numbers: number, dec: number, signed = true): RegExp {

    let pattern = signed ? "-?": "";

    if (numbers > dec) {
        if (numbers > dec + 3) {
            if (numbers == dec + 4) {
                pattern += "0?[1-9]?,?\\d{3}";
            } else if (numbers == dec + 5) {
                pattern += "0?[1-9]?[0-9]?,?\\d{3}";
            } else {
                pattern += "0?[1-9]?\\d{0," + (numbers - dec - 4).toString() + "},?\\d{1,3}";
            }
        } else if (numbers == dec + 1) {
            pattern += "0?[1-9]?";
        } else if (numbers == dec + 2) {
            pattern += "0?[1-9]?\\d{1}";
        } else {
            pattern += "0?[1-9]?\\d{1," + (numbers - dec - 1).toString() + "}";
        }
    }

    if (dec > 0) {
        if (dec > 1) {
            pattern += "(\\.\\d{1," + dec.toString() + "})?";
        } else {
            pattern += "(\\.\\d{1})?";
        }
    }

      return new RegExp("^" + pattern + "$");
}
