import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import axios from 'axios';

import { ErrorCode } from '../utils/messages';
import { EraseIcon } from '../icons/EraseIcon'
import { EmailIcon } from '../icons/EmailIcon'
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { ContactActionType, ContactContext, IContact, Property } from '../providers/ContactProvider';
import { AppActionType, AppContext } from '../providers/AppProvider';
import { ObjectValidation, ObjectValidationState } from './../utils/objectvalidation';
import { TranslatedContent } from './TranslatedContent';


const CONTACT_URL = 'https://react-api.softex.nl/softex/contacts';
const axiosResult = "data";

const initialErrors: Record<string, any> = {};
const initialTouched: Record<string, boolean> = {};

export const Contact = () => {
    const { t, i18n } = useTranslation();
    const content = "contact";
    let { language } = useParams();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const contactContext = useContext(ContactContext);
    const contact = contactContext.contact;
    const [ errors, setErrors ] = useState(initialErrors);
    const [ touched, setTouched ] = useState(initialTouched);
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
    
    // Check a language change from the browser's url
    if (language) {
        language = language.toLowerCase();
        // Return the languages supported:
        const langs = Object.keys(i18next.services.resourceStore.data);
        // Check whether it is supported
        if (langs.includes(language))
            if (language !== i18n.language)
                // This will redraw the Navigator
                i18n.changeLanguage(language);
    } else {
        language = i18n.language;
    }

    const errorSpanList: Record<string, RefObject<HTMLSpanElement>> = {};
    errorSpanList[Property.NAME] = useRef<HTMLSpanElement>(null);
    errorSpanList[Property.EMAIL] = useRef<HTMLSpanElement>(null);
    errorSpanList[Property.MESSAGE] = useRef<HTMLSpanElement>(null);

    const buttonColorStyle = {
        background: `linear-gradient(to bottom,  ${colorSchedule.buttonColorFrom} 0%,${colorSchedule.buttonColorTo} 100%)`, 
        borderColor: colorSchedule.buttonBorderColor};
    const errorColorStyle = {
        color: colorSchedule.errorColor};
    
    const validator = (obj: IContact, propertyName?: string): ObjectValidation => {
        const validator = new ObjectValidation(obj);
        if (propertyName == null || propertyName === Property.NAME)
            validator.get(Property.NAME)
                .validate(validator.notEmpty(Property.NAME), ErrorCode.IsRequired, "Name is required");
        if (propertyName == null || propertyName === Property.EMAIL)
            validator.get(Property.EMAIL)
                .validate(validator.notEmpty(Property.EMAIL), ErrorCode.IsRequired, "E-mail is required")
                .validate(validator.isValidExpression(Property.EMAIL, /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/), 
                                                       ErrorCode.InvalidExpression, "E-mail is an invalid expression");
        if (propertyName == null || propertyName === Property.MESSAGE)
            validator.get(Property.MESSAGE)
                .validate(validator.notEmpty(Property.MESSAGE), ErrorCode.IsRequired, "Message is required");
        return validator;
    }

    const validateProperty = async(contact: IContact, propertyName: string): Promise<void> => {
        const validate = validator(contact);
        const results = await validate.execute(ObjectValidationState.Create, propertyName);
        const refError = errorSpanList[propertyName];
        if (refError && refError.current) {
            refError.current.innerText = getLocalizedErrorMessage(results, propertyName);
            setErrors({...errors, [propertyName]: results[propertyName]});
        }
    }

    const getLocalizedErrorMessage = (errors: Record<string, any>, propertyName: string) => {
        let msg = "";
        if (errors && errors[propertyName]) {
            const errorCode = "Contact.Errors." + (propertyName + "-" + errors[propertyName]["message"]).toString().toLowerCase();
            msg = t(errorCode);
        }
        return msg;
    }

    const onChange = async(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        contactContext.dispatch([ 
                    { type: ContactActionType.SET_PROPERTY, name, value }, 
                    { type: ContactActionType.RUN, 
                        function: (contact: IContact) => { return touched[name] ? validateProperty(contact, name) : undefined; } }
        ]);
    };

    const onExit = async(e: any) => {
        const { name } = e.currentTarget;
        validateProperty(contact, name);
        setTouched({...touched, [name]: true});
    };

    const onClear = (e: React.FormEvent<HTMLButtonElement>) => {
        contactContext.dispatch({ type: ContactActionType.CLEAR });
        setErrors(initialErrors);
        setTouched(initialTouched);
    };

    const onSend = async(e: React.FormEvent<HTMLButtonElement>) => {
        alert("Not yet implemented");
        // const results = await validator(contact).execute(ObjectValidationState.Create);
        // if (Object.keys(results).length === 0) {
        //     contactContext.dispatch({ type: ContactActionType.SEND });
        //     await axios.post(CONTACT_URL, { name: contact.name, email: contact.email, message: contact.message })
        //         .then((res: any) => {
        //             // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //             const response = res[axiosResult];
        //             //const msg = t("contact.sent");
        //             contactContext.dispatch({ type: ContactActionType.SENT });
        //             setErrors(initialErrors);
        //             setTouched(initialTouched);
        //         })
        //         .catch((exc: Error) => {
        //             alert(JSON.stringify(exc));
        //         });
        // } else {
        //      setErrors(results);
        // }
    };

    useEffect(() => {
        if (content !== context.app.content) {
            context.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            if (window.history.state.key == null) {
                console.log(`Goto /${content}/${language}`);
                navigate(`/${content}/${language}`, { replace: false });
            }
        }        
    })

    return (
        <>
            <TranslatedContent content="contact" />
            <Styles>
                <div className="box">
                    <div className="title">
                        <form id="contact-form">
                            <div className="form">
                                <div className="form-group">
                                    <label htmlFor={Property.NAME}>{t("Contact.Name")}</label>
                                    <input type="text" required={true} className="form-control" aria-describedby="nameHelp"
                                        name={Property.NAME} value={contact.name} onChange={onChange} onBlur={onExit} />
                                    <span key="nameError" ref={errorSpanList[Property.NAME]} style={errorColorStyle} className="error">
                                        { getLocalizedErrorMessage(errors, Property.NAME) }
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor={Property.EMAIL}>{t("Contact.Email")}</label>
                                    <input type="email" className="form-control" aria-errormessage="emailHelp"  aria-describedby="emailHelp" 
                                        name={Property.EMAIL} value={contact.email} onChange={onChange} onBlur={onExit} />
                                    <span key="emailError" ref={errorSpanList[Property.EMAIL]} style={errorColorStyle} className="error">
                                        { getLocalizedErrorMessage(errors, Property.EMAIL) }
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor={Property.MESSAGE}>{t("Contact.Message")}</label>
                                    <textarea className="form-control" rows={5} name={Property.MESSAGE} value={contact.message} onChange={onChange} onBlur={onExit} />
                                    <span key="messageError" ref={errorSpanList[Property.MESSAGE]} style={errorColorStyle} className="error">
                                        { getLocalizedErrorMessage(errors, Property.MESSAGE) }
                                    </span>
                                </div>
                                <button type="button" style={buttonColorStyle} className="button" onClick={onClear}>
                                    <EraseIcon color={colorSchedule.buttonTextColor}/>
                                </button>&nbsp;
                                <button type="button" style={buttonColorStyle} className="button" onClick={onSend}>
                                    <EmailIcon color={colorSchedule.buttonTextColor}/>
                                </button>
                            </div>
                        </form>
                     </div>
                </div>
            </Styles>
        </>
    )
}

const Styles = styled.div`
.html {
    padding: 0px;
    padding-top: 25px;
    margin-left: 15px;
}
.box {
    min-height: 500px; border: solid 1px; 
    margin: 0px; margin-right: 25px; margin-bottom: 25px;  
    background-color: pink;        
    padding-bottom: 15px;
}
.title {
//    font-weight: bold;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}

.form {
    margin-top: 15px;
}

.error {
   font-size: small;
}

.button {
    height: 35px;
    width: 50px;
}
`;
