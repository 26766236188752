import * as React from "react";
import * as ReactDOM from "react-dom";

//import './index.css';
//import * as reportWebVitals from './reportWebVitals';
import './i18n';
import App from './components/App';
import { ColorScheduleProvider } from "./providers/ColorScheduleProvider";

ReactDOM.render(
  <React.StrictMode>
    <ColorScheduleProvider>
      <App/>
    </ColorScheduleProvider>
  </React.StrictMode>
  , document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
