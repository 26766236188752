import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { CompanyBullet } from '../icons/CompanyBullet';
import { WeAutomateAutomationIcon } from '../icons/WeAutomateAutomationIcon';
import { useMediaQuery } from 'react-responsive';

import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { AppActionType, AppContext } from '../providers/AppProvider';

export const TranslatedContent = (props: any) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let { content, language } = props;
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const colorContext = useContext(ColorScheduleContext);
    const colorSchedule = colorContext.colorSchedule;
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    // Check a language change from the browser's url
    if (language) {
        language = language.toLowerCase();
        // Return the languages supported:
        const langs = Object.keys(i18next.services.resourceStore.data);
        // Check whether it is supported
        if (langs.includes(language))
            if (language !== i18n.language)
                // This will redraw the Navigator
                i18n.changeLanguage(language);
    } else {
        language = i18n.language;
    }

    const changeContent = (value: string) => {
        const contentName = value;
        //alert(value);
        navigate(`/${contentName}/${language}`, { replace: false });
    };

    useEffect(() => {
        if (content !== context.app.content) {
            context.dispatch([{ type: AppActionType.CHANGE_CONTENT, payLoad: content }])
            if (window.history.state.key == null) {
                console.log(`Goto /${content}/${language}`);
                navigate(`/${content}/${language}`, { replace: false });
            }
        }        
    })

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [content]);    

      
    const textStyle = { 
        color: colorSchedule.surfaceTextColor
    }
    const linkColor = colorSchedule.linkColor;
    const key = `TranslatedContent.${content?.toLowerCase()}.Items`;    
    const components = { 
        italic: <i />, bold: <strong />
    };
    let index = -1;

    return (
        <Styles isMobile={isMobile} linkColor={linkColor}>
            <div className="box" key="content">
                <div key="inner_content">               
                    {(t(key, { returnObjects: true }) as any).map((item: any) => {                        
                        index++;
                        const section = key + "." + index;                        
                        const titleKey = section + ".Title";
                        const paragraphKey = section + ".Paragraph";
                        const title = t(titleKey) == "" ? (<></>) : (<div key={section + "_title"} className='title'>
                                     <Trans key={section + "_trans_title"} i18nKey={titleKey} components={
                                         {goto: <GotoContent translation={Trans} linkColor={colorSchedule.linkColor}/>, ...components }}>
                                     </Trans>
                                 </div>);
                        const paragraph = t(paragraphKey) == "" ? (<></>) : (<div key={section + "_paragraph"} className='paragraph'>
                                    <Trans key={section + "_trans_paragraph"} i18nKey={paragraphKey} components={
                                        {goto: <GotoContent translation={Trans} linkColor={colorSchedule.linkColor}/>, ...components }}>
                                    </Trans>
                                </div>);                    
                        return (
                            <> 
                                {title}
                                {paragraph}
                            </>
                        );
                    })}                                    
                </div>            
            </div>            
        </Styles>
      );}

const Styles = styled.div<{ isMobile: boolean, linkColor: string }>`
    .html {
        padding: 0px;
        padding-top: 25px;
        margin-left: 15px;
    }
    .title {
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
    }
    .main-title {
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
    }    
    .paragraph {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 0px;
    }
    a {
        color: ${(props) => props.linkColor};
    }    
`;

export const GotoContent = (props: any) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const changeContent = (value: string) => {
        navigate(`/${value}`, { replace: false });
    };

    const values = (props.children[0] + "").split(";");
    const name = values[0];
    const content = values[values.length > 1 ? 1 : 0];

    return (
        <a  key={"innerLink_" + name}
            onMouseEnter={()=>{
                setHover(true);
            }}
            onMouseLeave={()=>{
                setHover(false);
            }}
            style={{
                color: props.linkColor,
                cursor: "pointer",
                textDecoration: hover ? "underline" : "none"
            }}
            onClick={() => changeContent(content)}>{name}</a>
    );
};