/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum MessageType {
    Both = 0,
    Message = 1,
    Code = 2,
    CodeAsMessage = 3,
    Template = 4
  }

export let defaultMessageType: MessageType = MessageType.CodeAsMessage;

export const ErrorCode = {
    Invalid: "INVALID",
    IsRequired: "IS-REQUIRED",
    IsEmpty: "IS-EMPTY",
    InvalidExpression: "INVALID-EXPRESSION", 
    InvalidDateTime: "INVALID-DATETIME",
    InvalidSize: "INVALID-SIZE",
    InvalidDate: "INVALID-DATE",
    InvalidTime: "INVALID-TIME", 
    InvalidBoolean: "INVALID-BOOLEAN", 
    InvalidNumber: "INVALID-NUMBER",
    OutOfRange: "OUT-OF-RANGE", 

    InvalidReference: "INVALID-REFERENCE",
    IsDuplicate: "IS-DUPLICATE",
    InvalidVersion: "INVALID-VERSION",

    NotValidated: "NOT-VALIDATED",
    NotSaved: "NOT-SAVED",
    NotDeleted: "NOT-DELETED",
    NotFound: "NOT-FOUND",
    IsReference: "IS-REFERENCE",
    Exception: "EXCEPTION",
    UnAuthorized: "UNAUTHORIZED"
}

export const Code = {
    Authorized: "AUTHORIZED",
    Valid: "VALID",
    Validated: "VALIDATED",
    Saved: "SAVED",
    Deleted: "DELETED",
}

export const setMessageType = (messageType: MessageType): void => {
    defaultMessageType = messageType;
}

export const setMessage = (output: any, code: string, message: string, messageType: MessageType = defaultMessageType): void => {
    switch(messageType) {
        case MessageType.Code:
            output.code = code;
            break;
        case MessageType.Message:
            output.message = message;
            break;
        case MessageType.CodeAsMessage:  
            output.message = code;
            break;
        case MessageType.Template:  
            output.message = `[${code}]: ${message}`;
            break;
        default:
            output.code = code;
            output.message = message;
      }
}
