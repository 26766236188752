import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';


export const Layout = (props: any) => (
    <Styles>
        <div className="layout">
            <Container>
                {props.children}
            </Container>
        </div>
    </Styles>
);

const Styles = styled.div`
.layout {
    vertical-align: top;
    padding: 0px;
    margin: 0px;
}
`;