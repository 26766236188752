import * as React from "react";

export const MoonIcon = ({
    height = "24px",
    width = "24px",
    color = "yellow",
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}>
        <path d="M2 12A10 10 0 0 0 15 21.54A10 10 0 0 1 15 2.46A10 10 0 0 0 2 12Z" fill={color} />
    </svg>
  );