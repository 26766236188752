// TODO: Implement this via a logging library
export let display = false;

export const setDisplay = (value: boolean): void => {
    display = value;
}

export const output = (value: unknown, visible = display): void => {
    if (visible)
        console.log(value);
}

export const error = (value: unknown): void => {
    output(value, true);
}